import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvocaService } from '../../project/services/invoca.service';
import { GlobalVar } from '../../../../config';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable()

export class UsuarioService {

  private url_getAllUsuarios;
  private url_agregarUsuarios;
  private url_editarUsuarios;
  private url_eliminarUsuarios;

  constructor(private invoke: InvocaService, private http: HttpClient) {
    this.url_getAllUsuarios = GlobalVar.URL_BASE + 'usuario/getAllUsuarios';
    this.url_agregarUsuarios = GlobalVar.URL_BASE + 'usuario/agregarUsuarios';
    this.url_editarUsuarios = GlobalVar.URL_BASE + 'usuario/editarUsuarios';
    this.url_eliminarUsuarios = GlobalVar.URL_BASE + 'usuario/eliminarUsuarios';

  }

  private static handleError(error: any): Promise<any> {
    console.error('Ha ocurrido un error: ', error);
    return Promise.reject(error.message || error);
  }

  login(params?: any): Observable<any> {
    return this.invoke.httpInvoke("usuario/acceso", params);
  }

  getAllUsuarios(param): Observable<any> {
    return this.http
      .post(this.url_getAllUsuarios, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(UsuarioService.handleError));
  }

  agregarUsuarios(param): Observable<any> {
    return this.http
      .post(this.url_agregarUsuarios, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(UsuarioService.handleError));
  }

  eliminarUsuarios(param): Observable<any> {
    return this.http
      .post(this.url_eliminarUsuarios, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(UsuarioService.handleError));
  }

  editarUsuarios(param): Observable<any> {
    return this.http
      .post(this.url_editarUsuarios, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(UsuarioService.handleError));
  }

  

}

