import { Component, TemplateRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportesService } from '../../../services/reportes.service';
import { SucursalesService } from '../../../services/sucursales.service';
import { UxService } from '../../../services/ux.service';

import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { log } from 'util';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { RegistrosService } from '../../../services/registros.service';


const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-reporte-sucursales',
  templateUrl: './reporteSucursales.component.html',
  styleUrls: ['./reporteSucursales.component.scss']
})
export class ReporteSucursalesComponent implements OnInit {
  dataRegistros = [];
  reorderable: boolean;
  sucursales: any;
  sucursalSelect: number;
  cliente: number;

  showError: boolean = false;
  tipoError: string = "";
  msgError: string = "";
  iconError: string = "";
  panelError: string = "";

  fechaInicio: any;
  fechaTermino: any;
  mostrarTabla: boolean = false;

  locale = 'es';

  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  constructor(
    private router: Router,
    private reportesService: ReportesService,
    private sucursalesService: SucursalesService,
    private registrosService: RegistrosService,
    private ux: UxService,
    private localeService: BsLocaleService
  ) {
    this.reorderable = true;

    defineLocale(this.locale, esLocale);
    this.localeService.use(this.locale);

    this.sucursalSelect = -1;
    this.cliente = parseInt(localStorage.getItem('usu_cliente'), 10);
  }

  ngOnInit() {
    this.getSucursales((err, dataSucursales) => {
      if (err) {
        this.ux.alerta(err.message);
      } else {
        this.sucursales = dataSucursales;
      }
    });
  }

  getSucursales(callback) {

    this.showCargando();

    this.sucursalesService.getSucursales(this.cliente)
      .subscribe(dataSucursales => {

        this.hideCargando();
        callback(null, dataSucursales);

      }, err => {

        this.hideCargando();
        callback(err, null);

        console.log('Error al invocar a sucursalesService.getSucursales: ', err);
      });
  }

  filtrarReportesSucursal() {

    if (this.sucursalSelect != null && this.sucursalSelect !== -1 && this.fechaInicio != null && this.fechaTermino != null) {

      this.ocultarError();

      if (this.fechaInicio <= this.fechaTermino) {

        this.ocultarError();

        let dia = (this.fechaInicio.getDate() <= 9) ? '0' + (this.fechaInicio.getDate() + 1) : this.fechaInicio.getDate();
        let diaTermino = (this.fechaTermino.getDate() <= 9) ? '0' + (this.fechaTermino.getDate() + 1) : this.fechaTermino.getDate();

        let mes = (this.fechaInicio.getMonth() <= 9) ? '0' + (this.fechaInicio.getMonth() + 1) : this.fechaInicio.getMonth();
        let mesTermino = (this.fechaTermino.getMonth() <= 9) ? '0' + (this.fechaTermino.getMonth() + 1) : this.fechaTermino.getMonth();

        let param = {
          cliente: this.cliente,
          sucursal: this.sucursalSelect,
          fechaInicio: this.fechaInicio.getFullYear() + '-' + mes + '-' + dia + '' + 'T00:00:00',
          fechaTermino: this.fechaTermino.getFullYear() + '-' + mesTermino + '-' + diaTermino + '' + 'T23:59:00',
        }

        this.showCargando();

        this.reportesService.getReporteSucursal(param)
          .subscribe(dataReporte => {

            this.hideCargando();

            if (dataReporte.status === 'ERROR') {
              this.ux.alerta(dataReporte);
            } else if (dataReporte.status === 'OK') {

              this.dataRegistros = this.reportesService.mapReporteSucursal(dataReporte.data);
              this.dataRegistros = this.registrosService.deleteNulls(this.dataRegistros);

              this.mostrarTabla = true;

            }
          }, err => {

            this.hideCargando();

            console.log('Error al invocar a reportesService.getReporteSucursal: ', err);
          });

      } else {
        this.mostrarError('Advertencia', 'La fecha de inicio debe ser menor o igual a la fecha de término');
      }

    } else {
      this.mostrarError('Error', 'Favor debe ingresar una sucursal, fecha de inicio y fecha de término');
    }
  }


  // *********************************** DESCARGA REPORTE ***********************************


  generarCSV() {

    let cont = 1;

    var csv = 'ID;Sección;Botón;Acción;Cantidad\n';

    this.dataRegistros.forEach(row => {

      if (row != null) {
        csv += cont + ';' + row.seccion + ';' + row.nombreBtn + ';' + row.accion + ';' + row.cantidad;
        csv += '\n';
      }

      cont++;

    });

    this.generarDescarga(csv);
    this.ux.notificacion('Informe generado satisfactoriamente.', 'success');
  }

  generarDescarga(csv) {
    const f = new Date();
    const fecha = this.strDate(f.getUTCDate()) + '-' + this.strDate(f.getUTCMonth()) + '-' + f.getUTCFullYear() + '_' +
      this.strDate(f.getUTCHours()) + '_' + this.strDate(f.getUTCMinutes()) + '_' + this.strDate(f.getUTCSeconds());

    // console.log(csv);
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'reporte_sucursal_' + fecha + '.csv';
    hiddenElement.click();
  }


  //  *********************************** FUNCIONES DE VISTA ***********************************


  strDate(num: number) {
    return ('0' + num).substr(-2);
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

  mostrarError(tipo, msg) {

    this.tipoError = tipo

    switch (this.tipoError) {
      case 'Error':
        this.iconError = "fa fa-close";
        this.panelError = "alert-danger";

        break;

      case 'Advertencia':

        this.iconError = "fa fa-exclamation-circle";
        this.panelError = "alert-warning";

        break;

    }

    this.msgError = msg
    this.showError = true;
  }

  ocultarError() {
    this.showError = false;
  }

  setMenuActive(menu) {

    switch (menu) {
      case "registros":
        this.router.navigateByUrl('/registros');
        break;

      case "reporteSucursales":
        this.router.navigateByUrl('/reporteSucursales');
        break;

      case "reporteSecciones":
        this.router.navigateByUrl('/reporteSecciones');
        break;

      case "reporteBotones":
        this.router.navigateByUrl('/reporteBotones');
        break;

      case "reporteResumen":
        this.router.navigateByUrl('/reporteResumen');
        break;

    }

  }

}
