import { Component, OnInit, TemplateRef, ViewChild, Input, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParametrosService } from '../../services/parametros.service';
import { UxService } from '../../services/ux.service';

import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Router } from '@angular/router';
import { FormatoFechaHoraPipe } from '../../pipe/formato-fecha-hora.pipe';
import { SelectItem } from 'primeng/api';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.scss'],
  providers: [],
})
export class ParametrosComponent implements OnInit {

  cliente: number;
  fechaHoy: Date;
  retroceso: any;
  colorFondo: string;
  colorLetra: string;
  dimensionH: number;
  dimensionW: number;
  valorPerdida: number;
  valorFueraTiempo: number;

  valorPerdidaNonFood: number;
  valorFueraTiempoNonFood: number;

  fechaAct: string;
  resolucionSelect: any;
  habilitarInput: boolean;
  comboResolucion: SelectItem[];
  usuario: string;
  usuarioID: number;

  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  constructor(
    private formBuilder: FormBuilder,
    private parametrosService: ParametrosService,
    private router: Router,
    private ux: UxService
  ) {

    this.cliente = parseInt(localStorage.getItem('usu_cliente'), 10);

    this.usuario = localStorage.getItem('usu_nombre');
    this.usuarioID = parseInt(localStorage.getItem('usu_id'));

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "sinFondo";
    }

    this.comboResolucion = [];
    this.comboResolucion.push({ label: "Otra", value: 0 });
    this.comboResolucion.push({ label: "720p", value: 720 });
    this.comboResolucion.push({ label: "1080p", value: 1080 });

  }

  ngOnInit() {
    this.getParametros();

  }

  getParametros() {

    this.showCargando();

    let param = {
      cliente: this.cliente,
    }

    this.parametrosService.getParametros(param)
      .subscribe(data => {

        if (data.data.colorLetra != null) {

          this.colorFondo = data.data.colorFondo;
          this.colorLetra = data.data.colorLetra;
          this.dimensionH = data.data.dimensionH;
          this.dimensionW = data.data.dimensionW;
          this.valorPerdida = data.data.valorPerdida;
          this.valorFueraTiempo = data.data.valorFueraTiempo;

          this.valorPerdidaNonFood = data.data.valorPerdidaNonFood;
          this.valorFueraTiempoNonFood = data.data.valorFueraTiempoNonFood;


          if (data.data.fechaAct == "" || data.data.fechaAct == null) {
            this.fechaAct = "Sin información";
          } else {
            this.fechaAct = FormatoFechaHoraPipe.formatoFecha(data.data.fechaAct);
          }

          if (this.dimensionH == 1920) {
            this.resolucionSelect = 1080;
          } else if (this.dimensionH == 1280) {
            this.resolucionSelect = 720;
          } else {
            this.resolucionSelect = 0;
          }

        } else {

          this.retroceso = false;
          this.colorFondo = "#000000";
          this.colorLetra = "#696769";
          this.dimensionH = 400;
          this.dimensionW = 400;
          this.valorPerdida = 0;
          this.valorFueraTiempo = 0;
          this.fechaAct = "Sin información";

        }

        this.hideCargando();

      }, err => {
        this.hideCargando();
        console.log('Error al invocar a parametrosService.getParametros: ', err);
      });
  }

  guardarParametros() {

    this.fechaHoy = new Date();

    this.showCargando();

    if (this.resolucionSelect == 720) {
      this.dimensionH = 1280;
      this.dimensionW = 720;
    } else if (this.resolucionSelect == 1080) {
      this.dimensionH = 1920;
      this.dimensionW = 1080;
    } else {
      this.dimensionH = 400;
      this.dimensionW = 400;
    }

    let param = {
      cliente: this.cliente,
      colorFondo: this.colorFondo,
      colorLetra: this.colorLetra,
      dimensionH: this.dimensionH,
      dimensionW: this.dimensionW,
      valorFueraTiempo: this.valorFueraTiempo,
      valorPerdida: this.valorPerdida,
      valorFueraTiempoNonFood: this.valorFueraTiempoNonFood,
      valorPerdidaNonFood: this.valorPerdidaNonFood,
      fechaAct: this.fechaHoy
    }

    this.parametrosService.guardarParametros(param)
      .subscribe(data => {

        this.fechaAct = FormatoFechaHoraPipe.formatoFecha(data.data.fechaAct);

        this.ux.notificacion('Parámetros actualizados correctamente.', 'success');

        this.hideCargando();

      }, err => {
        this.hideCargando();
        console.log('Error al invocar a controlFilaService.guardarParametrosControlFila: ', err);
      });
  }


  //  *********************************** FUNCIONES DE VISTA ***********************************


  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
