import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';

import { ErrorComponent } from './base/error/error.component';

import { RegistrosGeneralesComponent } from '../app/project/component/registrosGenerales/registrosGenerales.component';

import { InicioAdmComponent } from '../app/project/component/administracion/inicioAdm/inicioAdm.component';
import { GestionarSucursalesComponent } from '../app/project/component/administracion/gestionarSucursales/gestionarSucursales.component';
import { GestionarSeccionesComponent } from '../app/project/component/administracion/gestionarSecciones/gestionarSecciones.component';
import { GestionarBotonesComponent } from '../app/project/component/administracion/gestionarBotones/gestionarBotones.component';

import { ParametrosComponent } from '../app/project/component/parametros/parametros.component';

import { InicioReportesComponent } from './project/component/reportes/inicioReportes/inicio.component';
import { ReporteSucursalesComponent } from '../app/project/component/reportes/reporteSucursales/reporteSucursales.component';
import { ReporteSeccionesComponent } from '../app/project/component/reportes/reporteSecciones/reporteSecciones.component';
import { ReporteBotonesComponent } from '../app/project/component/reportes/reporteBotones/reporteBotones.component';
import { ReporteResumenComponent } from '../app/project/component/reportes/reporteResumen/reporteResumen.component';

import { EstadisticasComponent } from '../app/project/component/estadisticas/estadisticas.component';
import { EstadisticasReporteComponent } from '../app/project/component/estadisticasReporte/estadisticasReporte.component';
import { ReporteGraficosComponent } from './project/component/reportes/reporteGraficos/reporteGraficos.component';
import { GestionarUsuariosComponent } from './project/component/administracion/gestionarUsuarios/gestionarUsuarios.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'gestionarSucursales', component: GestionarSucursalesComponent },
  { path: 'gestionarSecciones', component: GestionarSeccionesComponent },
  { path: 'gestionarBotones', component: GestionarBotonesComponent },
  { path: 'gestionarUsuarios', component: GestionarUsuariosComponent },

  { path: 'registrosGenerales', component: RegistrosGeneralesComponent },

  { path: 'estadisticas', component: EstadisticasComponent },
  { path: 'estadisticasReporte', component: EstadisticasReporteComponent },
  

  { path: 'inicioReportes', component: InicioReportesComponent },
  { path: 'inicioAdm', component: InicioAdmComponent },
  { path: 'reporteBotones', component: ReporteBotonesComponent },
  { path: 'reporteResumen', component: ReporteResumenComponent },
  { path: 'reporteSucursales', component: ReporteSucursalesComponent },
  { path: 'reporteSecciones', component: ReporteSeccionesComponent },
  { path: 'reporteGraficos', component: ReporteGraficosComponent },
  

  { path: 'parametros', component: ParametrosComponent },

  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
