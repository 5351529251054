import { Pipe, PipeTransform } from '@angular/core';
import { log } from 'util';

@Pipe({
  name: 'formatoFechaHora'
})
export class FormatoFechaHoraPipe implements PipeTransform {
  static strDate(num: number): string {
    return ('0' + num).toString().substr(-2);
  }

  static formatoFecha(value) {
    
    const fechaMensaje = new Date(value);
    const diaMensaje = fechaMensaje.getDate();
    const mesMensaje = fechaMensaje.getMonth();
    const anioMensaje = fechaMensaje.getFullYear();
    const horaMensaje = FormatoFechaHoraPipe.strDate(fechaMensaje.getHours()) + ':' +
      FormatoFechaHoraPipe.strDate(fechaMensaje.getMinutes()) + ':' + FormatoFechaHoraPipe.strDate(fechaMensaje.getSeconds());

    return FormatoFechaHoraPipe.strDate(diaMensaje) + '/' + FormatoFechaHoraPipe.strMesNumero(mesMensaje) + '/' + anioMensaje +
      ' - ' + horaMensaje;
  }

  static formatoFechaTerminoNull(value) {

    const fechaMensaje = new Date(value);

    fechaMensaje.setMinutes(fechaMensaje.getMinutes() + 3);


    const diaMensaje = fechaMensaje.getDate();
    const mesMensaje = fechaMensaje.getMonth();
    const anioMensaje = fechaMensaje.getFullYear();
    const horaMensaje = FormatoFechaHoraPipe.strDate(fechaMensaje.getHours()) + ':' +
      FormatoFechaHoraPipe.strDate(fechaMensaje.getMinutes()) + ':' + FormatoFechaHoraPipe.strDate(fechaMensaje.getSeconds());

    return FormatoFechaHoraPipe.strDate(diaMensaje) + '/' + FormatoFechaHoraPipe.strMesNumero(mesMensaje) + '/' + anioMensaje +
      ' - ' + horaMensaje;
  }

  static strMes(valor) {
    switch (valor) {
      case 0:
        return 'Enero';
      case 1:
        return 'Febrero';
      case 2:
        return 'Marzo';
      case 3:
        return 'Abril';
      case 4:
        return 'Mayo';
      case 5:
        return 'Junio';
      case 6:
        return 'Julio';
      case 7:
        return 'Agosto';
      case 8:
        return 'Septiembre';
      case 9:
        return 'Octubre';
      case 10:
        return 'Noviembre';
      case 11:
        return 'Diciembre';
    }
  }

  static strMesNumero(valor) {
    switch (valor) {
      case 0:
        return '01';
      case 1:
        return '02';
      case 2:
        return '03';
      case 3:
        return '04';
      case 4:
        return '05';
      case 5:
        return '06';
      case 6:
        return '07';
      case 7:
        return '08';
      case 8:
        return '09';
      case 9:
        return '10';
      case 10:
        return '11';
      case 11:
        return '12';
    }
  }

  transform(value): string {
    return FormatoFechaHoraPipe.formatoFecha(value);
  }

  static secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hrs = (h < 10) ? "0" + h : h;
    var min = (m < 10) ? "0" + m : m;
    var sg = (s < 10) ? "0" + s : s;

    var hDisplay = h > 0 ? hrs + (h == 1 ? ":" : ":") : "00:";
    var mDisplay = m > 0 ? min + (m == 1 ? ":" : ":") : "00:";
    var sDisplay = s > 0 ? sg + (s == 1 ? "" : "") : "00";
    return hDisplay + mDisplay + sDisplay;
  }
}
