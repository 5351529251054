import { Component, OnInit, TemplateRef, ViewChild, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UxService } from '../../../services/ux.service';
import { BotonesService } from '../../../services/botones.service';
import { SucursalesService } from '../../../services/sucursales.service';
import { SeccionesService } from '../../../services/secciones.service';


import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { log } from 'util';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';


@Component({
  selector: 'app-gestionar-botones',
  templateUrl: './gestionarBotones.component.html',
  styleUrls: ['./gestionarBotones.component.scss']
})
export class GestionarBotonesComponent implements OnInit {


  cliente: number;
  tipoUsuario: number;
  sucursalSelect: number;
  botonSelect: any;

  botones: any = [];
  sucursales: any = [];
  secciones: any = [];

  permiteRecord: string;
  identificador2RecordAdd: string = "";
  identificadorAdd: string = "";
  nombreBotonAdd: string = "";
  idSucursalAdd: number;
  idSeccionAdd: number;

  permiteRecordEdit: string;
  identificador2RecordEdit: string = "";
  identificadorEdit: string = "";
  nombreBotonEdit: string = "";
  idSucursalEdit: number;
  idSeccionEdit: number;

  loadingIndicator: boolean;
  reorderable: boolean;
  modalRef: BsModalRef;

  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };


  @ViewChild('modalEditar') modalEditar;
  @ViewChild('modalEliminar') modalEliminar;

  constructor(
    private router: Router,
    private ux: UxService,
    private modalService: BsModalService,
    private botonesService: BotonesService,
    private sucursalesService: SucursalesService,
    private seccionesService: SeccionesService,


  ) {
    this.loadingIndicator = true;
    this.reorderable = true;
    this.cliente = parseInt(localStorage.getItem('usu_cliente'), 10);
    this.tipoUsuario = parseInt(localStorage.getItem('usu_tipo'));

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "sinFondo";
    }


    this.getAllBotones();

  }

  ngOnInit() {
  }

  getAllBotones() {

    this.showCargando();

    let param = {
      cliente: this.cliente
    }

    this.botonesService.getAllBotones(param)
      .subscribe(dataBotones => {

        this.hideCargando();

        if (dataBotones.status === 'ERROR') {

          console.log('Error al invocar a botonesService.getAllBotones: ', dataBotones.error);

        } else if (dataBotones.status === 'OK') {

          this.botones = dataBotones.data;

          this.botones.forEach(boton => {

            if (boton.bot_permite_record != null) {

              if (boton.bot_permite_record == 1) {
                boton.permiteRecord = "SI";
              } else {
                boton.permiteRecord = "NO";
              }

            } else {
              boton.permiteRecord = " - "
            }

          });

        }

      }, err => {
        this.hideCargando();
        console.log('Error al invocar a botonesService.getAllBotones: ', err);
      });

  }

  getSucursales() {
    this.showCargando();
    this.sucursalesService.getSucursales(this.cliente)
      .subscribe(dataSucursales => {
        this.hideCargando();
        if (dataSucursales.status === 'ERROR') {
          console.log('Error al invocar a botonesService.getAllBotones: ', dataSucursales.error);
        } else if (dataSucursales.status === 'OK') {
          this.sucursales = dataSucursales.data;
        }
      }, err => {
        this.hideCargando();
        console.log('Error al invocar a sucursalesService.getSucursales: ', err);
      });
  }


  // ******************* FUNCION AGREGAR BOTONES  *******************


  agregarBotones() {

    if (this.idSucursalAdd == null || this.idSucursalAdd == 0 || this.idSeccionAdd == null || this.idSeccionAdd == 0 ||
      this.identificadorAdd == '' || this.nombreBotonAdd == '') {
      this.ux.notificacion('Debe completar los campos del botón.', 'danger');
    } else {

      this.showCargando();

      let param = {
        idCliente: this.cliente,
        idSucursal: this.idSucursalAdd,
        idSeccion: this.idSeccionAdd,
        idBoton: this.identificadorAdd,
        idBoton2Record: this.identificador2RecordAdd,
        nombreBoton: this.nombreBotonAdd,
        permiteRecord: (this.permiteRecord == "1") ? true : false
      }

      this.botonesService.agregarBotones(param)
        .subscribe(data => {

          this.hideCargando();

          if (data.status === 'ERROR') {

            console.log('Error al invocar a botonesService.agregarBotones: ', data.error);

          } else if (data.status === 'OK') {
            this.getAllBotones();

            this.idSucursalAdd = null;
            this.idSeccionAdd = null;
            this.identificadorAdd = "";
            this.nombreBotonAdd = "";
            this.permiteRecord = null;

          }

        }, err => {
          this.hideCargando();
          console.log('Error al invocar a botonesService.agregarBotones: ', err);
        });

      this.modalRef.hide();

    }

  }

  showModalAgregarBotones(template) {

    this.getSucursales();
    this.modalRef = this.modalService.show(template, { class: '', ignoreBackdropClick: true });
  }

  cerrarModalAgregarBotones() {

    this.idSucursalAdd = null;
    this.idSeccionAdd = null;
    this.identificadorAdd = "";
    this.nombreBotonAdd = "";
    this.permiteRecord = null;

    this.modalRef.hide();
  }


  // ******************* FUNCION EDITAR BOTONES *******************


  editarBotones() {

    if (this.idSucursalEdit == null || this.idSucursalEdit == 0 || this.idSeccionEdit == null || this.idSeccionEdit == 0 ||
      this.identificadorEdit == '' || this.nombreBotonEdit == '') {
      this.ux.notificacion('Debe completar los campos del botón.', 'danger');
    } else {

      this.showCargando();

      let param = {
        idCliente: this.cliente,
        idSucursal: this.idSucursalEdit,
        idSeccion: this.idSeccionEdit,
        idBoton: this.identificadorEdit,
        idBoton2Record: this.identificador2RecordEdit,
        nombreBoton: this.nombreBotonEdit,
        permiteRecord: (this.permiteRecordEdit == "1") ? true : false
      }

      this.botonesService.editarBotones(param)
        .subscribe(data => {

          this.hideCargando();

          if (data.status === 'ERROR') {

            console.log('Error al invocar a botonesService.editarBotones: ', data.error);

          } else if (data.status === 'OK') {
            this.getAllBotones();
          }

        }, err => {
          this.hideCargando();
          console.log('Error al invocar a botonesService.editarBotones: ', err);
        });

      this.modalRef.hide();

    }
  }

  showModalEditarBotones(template, boton) {

    this.botonSelect = boton;

    this.getSucursales();

    this.sucursalSelect = this.botonSelect.suc_id;

    this.getSeccionesSucursal((err, data) => {
      if (err) {
        this.ux.alerta(err.message);
      } else {

        this.secciones = data;

        this.idSucursalEdit = this.botonSelect.suc_id;
        this.idSeccionEdit = this.botonSelect.bot_seccion;
        this.identificadorEdit = this.botonSelect.bot_id;
        this.identificador2RecordEdit = this.botonSelect.bot_id_2_record;
        this.nombreBotonEdit = this.botonSelect.bot_nombre;
        this.permiteRecordEdit = (this.botonSelect.bot_permite_record) ? "1" : "0";

        this.modalRef = this.modalService.show(template, { class: '', ignoreBackdropClick: true });

      }
    });

  }

  cerrarModalEditarBotones() {
    this.modalRef.hide();
  }


  // ******************* FUNCION ELININAR BOTONES *******************


  eliminarBotones() {

    let param = {
      idCliente: this.cliente,
      idSucursal: this.botonSelect.suc_id,
      idSeccion: this.botonSelect.bot_seccion,
      idBoton: this.botonSelect.bot_id
    }

    this.botonesService.eliminarBotones(param)
      .subscribe(data => {

        this.hideCargando();

        if (data.status === 'ERROR') {

          console.log('Error al invocar a botonesService.eliminarBotones: ', data.error);

        } else if (data.status === 'OK') {
          this.getAllBotones();
        }

      }, err => {
        this.hideCargando();
        console.log('Error al invocar a botonesService.eliminarBotones: ', err);
      });

    this.modalRef.hide();
  }

  showModalEliminarBotones(template, boton) {
    this.botonSelect = boton;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  cerrarModalEliminarBotones() {
    this.modalRef.hide();
  }


  //  *********************************** FUNCIONES DE VISTA ***********************************


  onSucursalChange(event) {

    this.sucursalSelect = event;

    this.getSeccionesSucursal((err, data) => {
      if (err) {
        this.ux.alerta(err.message);
      } else {
        this.secciones = data;
      }
    });
  }

  getSeccionesSucursal(callback) {
    this.showCargando();
    this.seccionesService.getSeccionesSucursal(this.cliente, this.sucursalSelect)
      .subscribe(dataReporte => {
        this.hideCargando();
        if (dataReporte.status === 'ERROR') {
          callback(dataReporte.error, null);
        } else if (dataReporte.status === 'OK') {
          callback(null, dataReporte.data);
        }
      }, err => {
        this.hideCargando();
        console.log('Error al invocar a seccionesService.getSeccionesSucursal: ', err);
      });
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
