import { Component, OnInit, TemplateRef, ViewChild, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UxService } from '../../../services/ux.service';
import { BotonesService } from '../../../services/botones.service';
import { SucursalesService } from '../../../services/sucursales.service';
import { SeccionesService } from '../../../services/secciones.service';


import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { log } from 'util';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { UsuarioService } from 'src/app/project/services/usuario.service';
import { SelectItem } from 'primeng/api';
import { ThrowStmt } from '@angular/compiler';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';


@Component({
  selector: 'app-gestionar-usuarios',
  templateUrl: './gestionarUsuarios.component.html',
  styleUrls: ['./gestionarUsuarios.component.scss']
})
export class GestionarUsuariosComponent implements OnInit {


  cliente: number;
  tipoUsuario: number;
  sucursalSelect: number;

  usuarios: any = [];
  sucursales: any = [];
  sucursalesItem: SelectItem[];

  nombreUsuarioAdd: string = "";
  correoUsuarioAdd: string = "";
  passUsuarioAdd: string = "";
  tipoUsuarioAdd: number;
  selectedSucursalAdd: any;

  usuarioSelect: any;


  nombreUsuarioEdit: string = "";
  correoUsuarioEdit: string = "";
  passUsuarioEdit: string = "";
  tipoUsuarioEdit: number;
  selectedSucursalEdit: any;

  listTiposUsuario: SelectItem[];

  loadingIndicator: boolean;
  reorderable: boolean;
  modalRef: BsModalRef;
  isCelular: boolean = false;

  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };


  @ViewChild('modalEditar') modalEditar;
  @ViewChild('modalEliminar') modalEliminar;

  constructor(
    private router: Router,
    private ux: UxService,
    private modalService: BsModalService,
    private botonesService: BotonesService,
    private usuariosService: UsuarioService,
    private sucursalesService: SucursalesService,
    private seccionesService: SeccionesService,


  ) {

    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      // this.mostrarMsg("success", "Éxito", "Entro un celular.");
      this.isCelular = true;
    }

    this.loadingIndicator = true;
    this.reorderable = true;
    this.cliente = parseInt(localStorage.getItem('usu_cliente'), 10);
    this.tipoUsuario = parseInt(localStorage.getItem('usu_tipo'));

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "sinFondo";
    }


    this.sucursalesItem = [];

    this.listTiposUsuario = [
      { label: 'Administrador', value: 1 },
      { label: 'Monitoreo', value: 2 }
    ];

    this.getAllUsuarios();

  }

  ngOnInit() {
  }

  getAllUsuarios() {

    this.showCargando();

    let param = {
      cliente: this.cliente
    }

    this.usuariosService.getAllUsuarios(param)
      .subscribe(dataUsuarios => {

        this.hideCargando();

        if (dataUsuarios.status === 'ERROR') {
          this.ux.alerta(dataUsuarios.error.message);
        } else if (dataUsuarios.status === 'OK') {
          this.usuarios = dataUsuarios.data;
        }

      }, err => {
        this.hideCargando();
        this.ux.notificacion('Error en servicio getAllBotones.', 'danger');
      }, () => {
        this.getSucursales();
      });

  }

  getSucursales() {

    this.showCargando();

    this.sucursalesService.getSucursales(this.cliente)
      .subscribe(dataSucursales => {

        if (dataSucursales.status === 'ERROR') {
          this.ux.alerta(dataSucursales.error.message);
        } else if (dataSucursales.status === 'OK') {
          this.sucursales = dataSucursales.data;

          this.sucursales.forEach(element => {
            this.sucursalesItem.push({ label: element.suc_nombre, value: element.suc_id })
          });
        }

        this.hideCargando();
      }, err => {
        this.hideCargando();
        this.ux.notificacion('Error en servicio getSucursales.', 'danger');
      });
  }


  // ******************* FUNCION AGREGAR BOTONES  *******************


  agregarUsuarios() {

    if (this.nombreUsuarioAdd == null || this.nombreUsuarioAdd == "" || this.correoUsuarioAdd == null || this.correoUsuarioAdd == "" ||
      this.passUsuarioAdd == null || this.passUsuarioAdd == '' || this.tipoUsuarioAdd == null) {
      this.ux.notificacion('Debe completar los campos del usuario.', 'danger');
    } else if (this.validarCorreo(this.correoUsuarioAdd)) {

      this.showCargando();

      let param = {
        cliente: this.cliente,
        nombre: this.nombreUsuarioAdd,
        correo: this.correoUsuarioAdd,
        password: this.passUsuarioAdd,
        tipoUsuario: this.tipoUsuarioAdd,
        sucursales: this.selectedSucursalAdd
      }

      this.usuariosService.agregarUsuarios(param)
        .subscribe(data => {

          this.hideCargando();

          if (data.status === 'ERROR') {
            this.ux.alerta(data.error.message);
          } else if (data.status === 'OK') {
            this.getAllUsuarios();
            this.ux.notificacion('Usuario agregado correctamente.', 'success');
          }

        }, err => {
          this.hideCargando();
          this.ux.notificacion('Error en servicio agregarBotones.', 'danger');
        });

      this.cerrarModalAgregarUsuario();

    } else {
      this.ux.notificacion('Debe ingresar un correo válido.', 'warning');
    }

  }

  showModalAgregarUsuario(template) {
    this.modalRef = this.modalService.show(template, { class: '', ignoreBackdropClick: true });
  }

  cerrarModalAgregarUsuario() {

    this.nombreUsuarioAdd = "";
    this.correoUsuarioAdd = "";
    this.passUsuarioAdd = "";
    this.selectedSucursalAdd = "";
    this.tipoUsuarioAdd = 0;

    this.modalRef.hide();
  }


  // ******************* FUNCION EDITAR BOTONES *******************


  editarUsuarios() {

    if (this.nombreUsuarioEdit == null || this.nombreUsuarioEdit == "" || this.correoUsuarioEdit == null || this.correoUsuarioEdit == "" ||
      this.passUsuarioEdit == null || this.passUsuarioEdit == '' || this.tipoUsuarioEdit == null) {
      this.ux.notificacion('Debe completar los campos del usuario.', 'danger');
    } else if (this.validarCorreo(this.correoUsuarioEdit)) {

      this.showCargando();

      let param = {
        cliente: this.cliente,
        usuario: this.usuarioSelect.usu_id,
        nombre: this.nombreUsuarioEdit,
        correo: this.correoUsuarioEdit,
        pass: this.passUsuarioEdit,
        tipoUsuario: this.tipoUsuarioEdit,
        sucursales: this.selectedSucursalEdit,
      }

      this.usuariosService.editarUsuarios(param)
        .subscribe(data => {

          if (data.status === 'ERROR') {
            this.ux.alerta(data.error.message);
          } else if (data.status === 'OK') {
            this.getAllUsuarios();
            this.ux.notificacion('Usuario modificado correctamente.', 'success');
          }

          this.hideCargando();

        }, err => {
          this.hideCargando();
          this.ux.notificacion('Error en servicio editarUsuarios.', 'danger');
        });

      this.cerrarModalEditarUsuarios();

    } else {
      this.ux.notificacion('Debe ingresar un correo válido.', 'warning');
    }
  }

  showModalEditarUsuarios(template, usuario) {

    this.selectedSucursalEdit = [];

    this.usuarioSelect = usuario;

    this.nombreUsuarioEdit = usuario.usu_nombre;
    this.correoUsuarioEdit = usuario.usu_correo;
    this.passUsuarioEdit = usuario.usu_contrasena;
    this.tipoUsuarioEdit = usuario.usu_tipo;

    for (let index = 0; index < usuario.sucursales.length; index++) {
      this.selectedSucursalEdit.push(usuario.sucursales[index].suc_id);
    }

    this.modalRef = this.modalService.show(template, { class: '', ignoreBackdropClick: true });

  }

  cerrarModalEditarUsuarios() {

    this.nombreUsuarioEdit = "";
    this.correoUsuarioEdit = "";
    this.passUsuarioEdit = "";
    this.tipoUsuarioEdit = 0;

    this.modalRef.hide();
  }


  // ******************* FUNCION ELININAR BOTONES *******************


  eliminarUsuarios() {

    let param = {
      cliente: this.cliente,
      usuario: this.usuarioSelect.usu_id,
      correo: this.usuarioSelect.usu_correo
    }

    this.usuariosService.eliminarUsuarios(param)
      .subscribe(data => {

        this.hideCargando();

        if (data.status === 'ERROR') {
          this.ux.alerta(data.error.message);
        } else if (data.status === 'OK') {
          this.getAllUsuarios();
          this.ux.notificacion('Usuario eliminado correctamente.', 'success');
        }

      }, err => {
        this.hideCargando();
        this.ux.notificacion('Error en servicio eliminarUsuarios.', 'danger');
      });

    this.cerrarModalEliminarUsuarios();
  }

  showModalEliminarUsuarios(template, usuario) {
    this.usuarioSelect = usuario;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  cerrarModalEliminarUsuarios() {
    this.usuarioSelect = null;
    this.modalRef.hide();
  }


  //  *********************************** FUNCIONES DE VISTA ***********************************


  validarCorreo(correo) {
    var patron = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;

    if (correo.search(patron) == 0) {
      return true;
    } else {
      return false;
    }
  }


  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
