import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit {

  date: Date;
  anioActual: number;

  constructor() {

    this.date = new Date();
    this.anioActual = this.date.getFullYear();

  }

  ngOnInit() {
  }

}
