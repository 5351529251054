import { Injectable } from '@angular/core';
import { GlobalVar } from '../../../../config';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ReporteMonitoreo } from '../entities/ReporteMonitoreo';
import { FormatoFechaHoraPipe } from '../pipe/formato-fecha-hora.pipe';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {
  private url_list: string;
  private url_list1: string;

  constructor(
    private http: HttpClient
  ) {
    this.url_list = GlobalVar.URL_BASE + 'usuario/getParametros';
    this.url_list1 = GlobalVar.URL_BASE + 'usuario/guardarParametros';
  }

  private static handleError(error: any): Promise<any> {
    console.error('Ha ocurrido un error: ', error);
    return Promise.reject(error.message || error);
  }

  getParametros(param: any): Observable<any> {
    return this.http
      .post(this.url_list, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(ParametrosService.handleError));
  }

  guardarParametros(param: any): Observable<any> {
    return this.http
      .post(this.url_list1, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(ParametrosService.handleError));
  }


  // *************** SECCION MAPEO ***************

}

