import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatoFecha',
})
export class FormatoFechaPipe implements PipeTransform {
  static strDate(num: number): string {
    return ('0' + num).toString().substr(-2);
  }

  static formatoFecha(value) {
    const fechaMensaje = new Date(value);
    const fecha = new Date();
    const diaHoy = fecha.getUTCDate();
    const mesHoy = fecha.getUTCMonth();
    const anioHoy = fecha.getUTCFullYear();
    const diaMensaje = fechaMensaje.getUTCDate();
    const mesMensaje = fechaMensaje.getUTCMonth();
    const anioMensaje = fechaMensaje.getUTCFullYear();
    // const horaMensaje = FormatoFechaPipe.strDate(fechaMensaje.getUTCHours()) + ':' +
    // FormatoFechaPipe.strDate(fechaMensaje.getUTCMinutes());

    return FormatoFechaPipe.strDate(diaMensaje) + ' ' + FormatoFechaPipe.strMes(mesMensaje) + ' ' + anioMensaje;
  }

  static strMes(valor) {
    switch(valor) {
      case 0:
        return 'Enero';
      case 1:
        return 'Febrero';
      case 2:
        return 'Marzo';
      case 3:
        return 'Abril';
      case 4:
        return 'Mayo';
      case 5:
        return 'Junio';
      case 6:
        return 'Julio';
      case 7:
        return 'Agosto';
      case 8:
        return 'Septiembre';
      case 9:
        return 'Octubre';
      case 10:
        return 'Noviembre';
      case 11:
        return 'Diciembre';
    }
  }

  transform(value): string {
    return FormatoFechaPipe.formatoFecha(value);
  }
}
