import { Component, OnInit } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { GlobalVar } from '../../../../config';

@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
  styleUrls: ['./cargando.component.scss']
})
export class CargandoComponent implements OnInit {
  loading: boolean;
  loadingConfig: any;

  constructor() {
    this.loadingConfig = {
      animationType: ngxLoadingAnimationTypes.circle,
      primaryColour: GlobalVar.CARGANDO.COLOR_PRIMARIO,
      secondaryColour: GlobalVar.CARGANDO.COLOR_SECUNDARIO,
      backdropBorderRadius: '3px'
    };
  }

  ngOnInit() { }

  public showCargando() {
    this.loading = true;
    console.log('Habilitar cargando: ', this.loading);
  }

  public hideCargando() {
    this.loading = false;
    console.log('Deshabilitar cargando: ', this.loading);
  }
}
