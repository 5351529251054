import { Component, OnInit, TemplateRef, ViewChild, Input, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrosService } from '../../../services/registros.service';
import { ReportesService } from '../../../services/reportes.service';
import { SeccionesService } from '../../../services/secciones.service';
import { SucursalesService } from '../../../services/sucursales.service';
import { UxService } from '../../../services/ux.service';

import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { log } from 'util';
import { Router } from '@angular/router';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { CabeceraComponent } from 'src/app/base/cabecera/cabecera.component';

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-reporte-graficos',
  templateUrl: './reporteGraficos.component.html',
  styleUrls: ['./reporteGraficos.component.scss'],
  providers: [],
})
export class ReporteGraficosComponent implements OnInit {

  cliente: number;
  usuario: number;
  seccion: any;
  @ViewChild('tablaCasos') table: any;
  listData: any = [];
  rows: any = [];
  sucursalSelect: number;

  showError: boolean = false;
  tipoError: string = "";
  msgError: string = "";
  iconError: string = "";
  panelError: string = "";

  reorderable: boolean;
  fechaActualizacion: Date;
  submitted = false;
  tiposReporte: any;
  secciones: any;
  sucursales: any;

  fechaInicio: any;
  fechaTermino: any;
  fechaTerminoString;
  fechaInicioString;

  mostrarTabla: boolean = false;
  dataAsistencias: any;
  dataRetiros: any;
  dataCajas: any;

  locale = 'es';
  isCelular: boolean = false;

  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  constructor(
    private formBuilder: FormBuilder,
    private reportesService: ReportesService,
    private sucursalesService: SucursalesService,
    private router: Router,
    private ux: UxService,
    private localeService: BsLocaleService
  ) {

    this.cliente = parseInt(localStorage.getItem('usu_cliente'), 10);
    this.usuario = parseInt(localStorage.getItem('usu_id'), 10);

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "sinFondo";
    }

    this.reorderable = true;
    this.sucursalSelect = -1;

    defineLocale(this.locale, esLocale);
    this.localeService.use(this.locale);

    var currentUtcTime = new Date();
    this.fechaActualizacion = new Date(currentUtcTime.toLocaleString('en-CL', { timeZone: 'America/Lima' }));

    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      // this.mostrarMsg("success", "Éxito", "Entro un celular.");
      this.isCelular = true;
    }

  }

  ngOnInit() {

    this.getSucursales((err, dataSucursales) => {
      if (err) {
        this.ux.alerta(err.message);
      } else {
        this.sucursales = dataSucursales;
      }
    });

  }

  getSucursales(callback) {
    this.showCargando();

    this.sucursalesService.getSucursalesUser(this.cliente, this.usuario)
      .subscribe(dataSucursales => {
        this.hideCargando();

        if (dataSucursales.status === 'ERROR') {
          callback(dataSucursales.error, null);
        } else if (dataSucursales.status === 'OK') {
          callback(null, dataSucursales.data);
        }
      }, err => {
        this.hideCargando();

        console.log('Error al invocar a sucursalesService.getSucursalesUser: ', err);
      });
  }

  onSucursalChange() {

    let labels = [];
    let labelsCaja = []
    let datasets = [];

    if (this.sucursalSelect != null && this.sucursalSelect !== -1 &&
      this.fechaInicio != null && this.fechaTermino != null) {

      this.showCargando();

      let mes = ((this.fechaInicio.getMonth() + 1) <= 9) ? '0' + (this.fechaInicio.getMonth() + 1) : this.fechaInicio.getMonth() + 1;
      let mesTermino = ((this.fechaTermino.getMonth() + 1) <= 9) ? '0' + (this.fechaTermino.getMonth() + 1) : this.fechaTermino.getMonth() + 1;

      let dia = (this.fechaInicio.getDate() <= 9) ? '0' + (this.fechaInicio.getDate()) : this.fechaInicio.getDate();
      let diaTermino = (this.fechaTermino.getDate() <= 9) ? '0' + (this.fechaTermino.getDate()) : this.fechaTermino.getDate();

      this.fechaInicioString = dia + '-' + mes + '-' + this.fechaInicio.getFullYear();
      this.fechaTerminoString = diaTermino + '-' + mesTermino + '-' + this.fechaTermino.getFullYear();

      let param = {
        cliente: this.cliente,
        sucursal: this.sucursalSelect,
        fechaInicio: this.fechaInicio.getFullYear() + '-' + mes + '-' + dia + '' + 'T00:00:00',
        fechaTermino: this.fechaTermino.getFullYear() + '-' + mesTermino + '-' + diaTermino + '' + 'T23:59:00',
      }

      if (this.sucursalSelect !== -1) {
        this.reportesService.getReporteResumenGrafico(param)
          .subscribe(dataRegistros => {

            for (let index = 0; index < dataRegistros.fechas.length; index++) {
              labels.push(dataRegistros.fechas[index]);
            }

            for (let index = 0; index < dataRegistros.botonesEstadisticas.length; index++) {
              labelsCaja.push(dataRegistros.botonesEstadisticas[index].boton);
            }

            let total = [];
            let normal = [];
            let fueraTiempo = [];
            let perdida = [];

            let totalRetiro = [];
            let normalRetiro = [];
            let fueraTiempoRetiro = [];
            let perdidaRetiro = [];

            let normalCaja = [];
            let fueraTiempoCaja = [];
            let perdidaCaja = [];

            let normalRetiroCaja = [];
            let fueraTiempoRetiroCaja = [];
            let perdidaRetiroCaja = [];

            for (let index = 0; index < dataRegistros.resumen.length; index++) {

              if (dataRegistros.resumen[index].length > 0) {

                if (dataRegistros.resumen[index][0].cantTotal != null) {
                  total.push(dataRegistros.resumen[index][0].cantTotal);
                  totalRetiro.push(dataRegistros.resumen[index][0].cantTotalRetiro);
                }

                if (dataRegistros.resumen[index][0].normal != null) {
                  normal.push(dataRegistros.resumen[index][0].normal);
                  normalRetiro.push(dataRegistros.resumen[index][0].normalRetiro);

                }

                if (dataRegistros.resumen[index][0].fueraTiempo != null) {
                  fueraTiempo.push(dataRegistros.resumen[index][0].fueraTiempo);
                  fueraTiempoRetiro.push(dataRegistros.resumen[index][0].fueraTiempoRetiro);
                }

                if (dataRegistros.resumen[index][0].perdida != null) {
                  perdida.push(dataRegistros.resumen[index][0].perdida);
                  perdidaRetiro.push(dataRegistros.resumen[index][0].perdidaRetiro);
                }

              } else {

                total.push(0);
                normal.push(0);
                fueraTiempo.push(0);
                perdida.push(0);

                totalRetiro.push(0);
                normalRetiro.push(0);
                fueraTiempoRetiro.push(0);
                perdidaRetiro.push(0);

              }

            }

            for (let index = 0; index < dataRegistros.botonesEstadisticas.length; index++) {

              if (dataRegistros.botonesEstadisticas[index].normal != null) {
                normalCaja.push(dataRegistros.botonesEstadisticas[index].normal);
                normalRetiroCaja.push(dataRegistros.botonesEstadisticas[index].normalRetiro);
              }

              if (dataRegistros.botonesEstadisticas[index].fueraTiempo != null) {
                fueraTiempoCaja.push(dataRegistros.botonesEstadisticas[index].fueraTiempo);
                fueraTiempoRetiroCaja.push(dataRegistros.botonesEstadisticas[index].fueraTiempoRetiro);
              }

              if (dataRegistros.botonesEstadisticas[index].perdida != null) {
                perdidaCaja.push(dataRegistros.botonesEstadisticas[index].perdida);
                perdidaRetiroCaja.push(dataRegistros.botonesEstadisticas[index].perdidaRetiro);
              }

            }

            this.dataAsistencias = {
              labels: labels,
              datasets: [
                {
                  label: 'Total',
                  data: total,
                  fill: false,
                  borderColor: '#1E88E5',
                },
                {
                  label: 'Normal',
                  data: normal,
                  fill: false,
                  borderColor: '#7CB342',
                },
                {
                  label: 'Fuera de tiempo',
                  data: fueraTiempo,
                  fill: false,
                  borderColor: '#FFCE56',
                },
                {
                  label: 'Pérdida',
                  data: perdida,
                  fill: false,
                  borderColor: '#ed2f32',
                }
              ]
            }

            this.dataRetiros = {
              labels: labels,
              datasets: [
                {
                  label: 'Total',
                  data: totalRetiro,
                  fill: false,
                  borderColor: '#1E88E5',
                },
                {
                  label: 'Normal',
                  data: normalRetiro,
                  fill: false,
                  borderColor: '#7CB342',
                },
                {
                  label: 'Fuera de tiempo',
                  data: fueraTiempoRetiro,
                  fill: false,
                  borderColor: '#FFCE56',
                },
                {
                  label: 'Pérdida',
                  data: perdidaRetiro,
                  fill: false,
                  borderColor: '#ed2f32',
                }
              ]
            }


            this.dataCajas = {
              labels: labelsCaja,
              datasets: [
                {
                  label: 'Normal',
                  backgroundColor: '#9CCC65',
                  borderColor: '#7CB342',
                  data: normalCaja
                },
                {
                  label: 'Fuera de tiempo',
                  backgroundColor: '#FFCE56',
                  borderColor: '#FFCE56',
                  data: fueraTiempoCaja
                },
                {
                  label: 'Pérdida',
                  backgroundColor: '#ed2f32',
                  borderColor: '#ed2f32',
                  data: perdidaCaja
                }
              ]
            }

            this.mostrarTabla = true;

            this.hideCargando();

          });
      }

    } else {
      this.ux.notificacion('Favor debe ingresar una sucursal, fecha de inicio y fecha de término.', 'danger');
    }


  }


  //  *********************************** FUNCIONES DE VISTA ***********************************


  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

  setMenuActive(menu) {

    switch (menu) {
      case "registros":
        this.router.navigateByUrl('/registros');
        break;

      case "reporteSucursales":
        this.router.navigateByUrl('/reporteSucursales');
        break;

      case "reporteSecciones":
        this.router.navigateByUrl('/reporteSecciones');
        break;

      case "reporteBotones":
        this.router.navigateByUrl('/reporteBotones');
        break;

      case "reporteGraficos":
        this.router.navigateByUrl('/reporteGraficos');
        break;

      case "casos":
        this.router.navigateByUrl('/casos');
        break;

      case "reporteResumen":
        this.router.navigateByUrl('/reporteResumen');
        break;

    }

  }

  generarCSV() {

    let cont = 1;

    var csv = 'ID;Sucursal;Cantidad Total;Normales;Fuera de tiempo;Insistencia;Pérdida;Promedio;Rendimiento\n';

    this.listData.forEach(row => {
      if (row != null) {
        csv += cont + ';' + row.suc_nombre + ';' + row.cantTotales + ';' + row.normal + ';' + row.fueraTiempo + ';' + row.insistencia + ';' + row.perdida + ';' + row.promedio + ';' + row.rendimiento;
        csv += '\n';
      }
      cont++;
    });

    this.generarDescarga(csv);

  }

  generarDescarga(csv) {

    this.showCargando();

    const f = new Date();
    const fecha = this.strDate(f.getUTCDate()) + '-' + this.strDate(f.getUTCMonth()) + '-' + f.getUTCFullYear() + '_' +
      this.strDate(f.getUTCHours()) + '_' + this.strDate(f.getUTCMinutes()) + '_' + this.strDate(f.getUTCSeconds());


    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'reporte_resumen_' + fecha + '.csv';
    hiddenElement.click();

    this.ux.notificacion('Informe generado satisfactoriamente.', 'success');

    this.hideCargando();
  }

  strDate(num: number) {
    return ('0' + num).substr(-2);
  }

  descargarReporte() {

    this.showCargando();
    let data = document.getElementById('reporteGraficos');

    const options = {
      background: 'white',
      scale: 1,
      allowTaint: true,
      scrollX: 0,
      scrollY: -window.scrollY
    };

    html2canvas(data, options).then((canvas) => {

      var imgWidth = 210;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('reporte_graficos_' + this.fechaInicioString + '_' + this.fechaTerminoString + '.pdf'); // Generated PDF 

      this.hideCargando();

    });

  }

}
