import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GlobalVar } from '../../../../config';
import { log } from 'util';

@Injectable({
  providedIn: 'root'
})
export class SeccionesService {
  private url_list: string;
  private url_listSucursal: string;
  private url_agregarSecciones: string;
  private url_editarSecciones: string;
  private url_eliminarSecciones: string;

  constructor(private http: HttpClient) {
    this.url_list = GlobalVar.URL_BASE + 'secciones/getAllSecciones';
    this.url_listSucursal = GlobalVar.URL_BASE + 'secciones/getSeccionesSucursal';
    this.url_agregarSecciones = GlobalVar.URL_BASE + 'secciones/agregarSecciones';
    this.url_editarSecciones = GlobalVar.URL_BASE + 'secciones/editarSecciones';
    this.url_eliminarSecciones = GlobalVar.URL_BASE + 'secciones/eliminarSecciones';
  }

  private static handleError(error: any): Promise<any> {
    console.error('Ha ocurrido un error: ', error);
    return Promise.reject(error.message || error);
  }

  getSecciones(cliente: number): Observable<any> {
    const arg = {
      cliente: cliente
    };

    return this.http
      .post(this.url_list, { arg: JSON.stringify(arg) })
      .pipe(map(data => data))
      .pipe(catchError(SeccionesService.handleError));
  }

  getSeccionesSucursal(cliente: number, sucursal: number): Observable<any> {
    const arg = {
      cliente: cliente,
      sucursal: sucursal
    };

    return this.http
      .post(this.url_listSucursal, { arg: JSON.stringify(arg) })
      .pipe(map(data => data))
      .pipe(catchError(SeccionesService.handleError));
  }

  agregarSecciones(param): Observable<any> {
    
    return this.http
      .post(this.url_agregarSecciones, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(SeccionesService.handleError));
  }

  editarSecciones(param): Observable<any> {
    return this.http
      .post(this.url_editarSecciones, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(SeccionesService.handleError));
  }

  eliminarSecciones(param): Observable<any> {
    return this.http
      .post(this.url_eliminarSecciones, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(SeccionesService.handleError));
  }


}
