import { BsModalRef } from 'ngx-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modal-content',
  templateUrl: './alerta.component.html'
})
export class AlertaComponent implements OnInit {
  titulo: string;
  closeBtnName: string;
  mensaje: any;

  constructor(public modalAlerta: BsModalRef) { }

  ngOnInit() {
  }

}
