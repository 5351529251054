import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ErrorBase } from '../entities/errorbase';
import { GlobalVar } from '../../../../config';

@Injectable()
export class InvocaService {

    constructor(
        private http: HttpClient
    ) { }

    private getHeaders(): HttpHeaders {
        return new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8')
            .set('Authorization', window.sessionStorage.getItem("token") == null ? '' : window.sessionStorage.getItem("token"));
    }

    httpInvoke(serviceName: string, params?: any): Observable<any> {
        var args = params == null ? {} : params;
        let arg = 'arg=' + encodeURIComponent(JSON.stringify(args));

        let url = GlobalVar.URL_BASE;

        return this.http.post(`${url}${serviceName}`, arg, { headers: this.getHeaders() })
            .pipe(map(this.mapResultado))
            .pipe(catchError(this.serverError))
    }

    httpInvoke_custom(urlService: string, params?: any): Observable<any> {
        var args = params == null ? {} : params;
        let arg = 'arg=' + encodeURIComponent(JSON.stringify(args));

        return this.http.post(`${urlService}`, arg, { headers: this.getHeaders() })
            .pipe(map(this.mapResultado))
            .pipe(catchError(this.serverError))
    }

    private serverError(error) {
        if (error instanceof Response || error instanceof HttpErrorResponse) return throwError(new ErrorBase(new Error("Error de servidor (Server error)"), true, false, null));
        return throwError(error || 'Error de comunicación controlador (Comunication backend error)');
    }

    private mapResultado(response: any): Observable<any> {

        if (response.status == "OK") {
            return response.data;
        } else {
            let validacion = {};
            let msgError: string = response.error.message;
            let error = new Error(msgError);
            let fatal: boolean = response.error.type == "FATAL";
            let expirado: boolean = msgError.indexOf('tokenError:') >= 0;
            let code_error: string = response.error.hasOwnProperty("code") ? response.error.code : "";

            switch (response.error.type) {
                case "ERROR":
                    if (msgError == 'Invalid Credentials') msgError = 'La contraseña ingresada no corresponde a la del usuario';
                    error.message = msgError;
                    validacion = { error: [{ severity: 'error', detail: msgError }], movil: msgError };
                    break;
                case "FATAL":
                    error.message = msgError;
                    validacion = { error: msgError, movil: msgError }
                    break;
            }

            throw new ErrorBase(error, fatal, expirado, validacion, code_error);
        }
    }

}
