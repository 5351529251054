import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inicioAdm',
  templateUrl: './inicioAdm.component.html',
  styleUrls: ['./inicioAdm.component.scss']
})
export class InicioAdmComponent implements OnInit {
  usuario: string;
  usuarioID: number;

  constructor(
    private router: Router
  ) {
    this.usuario = localStorage.getItem('usu_nombre');
    this.usuarioID = parseInt(localStorage.getItem('usu_id'));
    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "sinFondo";
    }

  }

  ngOnInit() {
    if (!localStorage.getItem('token')) {
      this.router.navigateByUrl('/');
    }
  }
}
