import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Injectable, ViewChild } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { GlobalVar } from '../../../../config';
import { AlertaComponent } from '../../base/alerta/alerta.component';
import { CargandoComponent } from '../../base/cargando/cargando.component';
import { NgxNotifierService } from 'ngx-notifier';

@Injectable({
  providedIn: 'root'
})
export class UxService {
  modalAlerta: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private toastService: NgxNotifierService,
    private cargandoComponent: CargandoComponent
  ) {
  }

  showCargando() {
    this.cargandoComponent.showCargando();
  }

  hideCargando() {
    this.cargandoComponent.hideCargando();
  }

  alerta(titulo: string, mensaje: string = '') {
    const initialState = {
      titulo: mensaje.length === 0 ? '¡Atención!' : titulo,
      mensaje: mensaje.length === 0 ? titulo : mensaje
    };

    this.modalAlerta = this.modalService.show(AlertaComponent, { initialState });
    this.modalAlerta.content.closeBtnName = 'Cerrar';
  }

  notificacion(mensaje: string, tipo: string, tiempo: number = 5000) {

    let mensajeFinal = "";

    switch (tipo) {
      case 'success':
        mensajeFinal = '<i class="fa fa-check"></i> ' + mensaje;
        break;

      case 'info':
        mensajeFinal = '<i class="fa fa-info"></i> ' + mensaje;
        break;

      case 'warning':
        mensajeFinal = '<i class="fa fa-exclamation-circle"></i> ' + mensaje;
        break;

      case 'danger':
        mensajeFinal = '<i class="fa fa-close"></i> ' + mensaje;
        break;
    }

    this.toastService.createToast(mensajeFinal, tipo, tiempo);
  }
}
