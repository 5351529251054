import { Component, OnInit, TemplateRef, ViewChild, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UxService } from '../../../services/ux.service';
import { SucursalesService } from '../../../services/sucursales.service';

import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { log } from 'util';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';


@Component({
  selector: 'app-gestionar-sucursales',
  templateUrl: './gestionarSucursales.component.html',
  styleUrls: ['./gestionarSucursales.component.scss']
})
export class GestionarSucursalesComponent implements OnInit {


  cliente: number;
  sucursales: any = [];
  sucursalSelect: any;

  nombreSucursalAdd: string = "";
  direccionSucursalAdd: string = "";

  nombreSucursalEdit: string = "";
  direccionSucursalEdit: string = "";

  loadingIndicator: boolean;
  reorderable: boolean;
  modalRef: BsModalRef;

  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };


  @ViewChild('modalEditar') modalEditar;
  @ViewChild('modalEliminar') modalEliminar;

  constructor(
    private router: Router,
    private ux: UxService,
    private modalService: BsModalService,
    private sucursalesService: SucursalesService,
  ) {
    this.loadingIndicator = true;
    this.reorderable = true;
    this.cliente = parseInt(localStorage.getItem('usu_cliente'), 10);

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "sinFondo";
    }


    this.getSucursales();

  }

  ngOnInit() {
  }

  getSucursales() {

    this.showCargando();

    this.sucursalesService.getSucursales(this.cliente)
      .subscribe(dataSucursales => {

        this.hideCargando();

        if (dataSucursales.status === 'ERROR') {

          console.log('Error al invocar a sucursalesService.getSucursales: ', dataSucursales.error);

        } else if (dataSucursales.status === 'OK') {

          this.sucursales = dataSucursales.data;

        }

      }, err => {
        this.hideCargando();
        console.log('Error al invocar a registrosService.getSucursales: ', err);
      });

  }


  // ******************* FUNCION AGREGAR SUCURSAL *******************


  agregarSucursal() {

    if (this.nombreSucursalAdd == '' || this.direccionSucursalAdd == '') {
      this.ux.notificacion('Debe completar los campos de la sucursal.', 'danger');
    } else {

      this.showCargando();

      let param = {
        idCliente: this.cliente,
        nombre: this.nombreSucursalAdd,
        direccion: this.direccionSucursalAdd
      }

      this.sucursalesService.agregarSucursal(param)
        .subscribe(data => {

          this.hideCargando();

          if (data.status === 'ERROR') {

            console.log('Error al invocar a sucursalesService.agregarSucursal: ', data.error);

          } else if (data.status === 'OK') {
            this.getSucursales();
          }

        }, err => {
          this.hideCargando();
          console.log('Error al invocar a sucursalesService.agregarSucursal: ', err);
        });

      this.modalRef.hide();
    }
  }

  showModalAgregarSucursal(template) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  cerrarModalAgregarSucursal() {
    this.modalRef.hide();
  }


  // ******************* FUNCION EDITAR SUCURSAL *******************


  editarSucursal() {

    if (this.nombreSucursalEdit == '' || this.direccionSucursalEdit == '') {
      this.ux.notificacion('Debe completar los campos de la sucursal.', 'danger');
    } else {

      this.showCargando();

      let param = {
        idSucursal: this.sucursalSelect.suc_id,
        idCliente: this.cliente,
        nombre: this.nombreSucursalEdit,
        direccion: this.direccionSucursalEdit
      }

      this.sucursalesService.editarSucursal(param)
        .subscribe(data => {

          this.hideCargando();

          if (data.status === 'ERROR') {

            console.log('Error al invocar a sucursalesService.editarSucursal: ', data.error);

          } else if (data.status === 'OK') {
            this.getSucursales();
          }

        }, err => {
          this.hideCargando();
          console.log('Error al invocar a sucursalesService.editarSucursal: ', err);
        });

      this.modalRef.hide();

    }
  }

  showModalEditarSucursal(template, sucursal) {

    this.sucursalSelect = sucursal;

    this.nombreSucursalEdit = this.sucursalSelect.suc_nombre;
    this.direccionSucursalEdit = this.sucursalSelect.suc_direccion;

    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  cerrarModalEditarSucursal() {
    this.modalRef.hide();
  }


  // ******************* FUNCION ELININAR SUCURSAL *******************


  eliminarSucursal() {

    let param = {
      idSucursal: this.sucursalSelect.suc_id,
      idCliente: this.cliente
    }

    this.sucursalesService.eliminarSucursal(param)
      .subscribe(data => {

        this.hideCargando();

        if (data.status === 'ERROR') {

          console.log('Error al invocar a sucursalesService.eliminarSucursal: ', data.error);

        } else if (data.status === 'OK') {
          this.getSucursales();
        }

      }, err => {
        this.hideCargando();
        console.log('Error al invocar a sucursalesService.agregarSucursal: ', err);
      });

    this.modalRef.hide();
  }

  showModalEliminarSucursal(template, sucursal) {
    this.sucursalSelect = sucursal;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  cerrarModalEliminarSucursal() {
    this.modalRef.hide();
  }


  //  *********************************** FUNCIONES DE VISTA ***********************************


  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
