import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuReportesComponent } from '../../../../project/component/reportes/menuReportes/menuReportes.component';
import { log } from 'util';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
  providers: [MenuReportesComponent],
})
export class InicioReportesComponent implements OnInit {
  usuario: string;
  usuarioID: number;

  menuReportes: MenuReportesComponent;

  constructor(
    private router: Router
  ) {

    this.usuario = localStorage.getItem('usu_nombre');
    this.usuarioID = parseInt(localStorage.getItem('usu_id'));

    var classFondo = document.getElementsByClassName("fondo")[0];

    if(classFondo != null && classFondo != undefined){
      classFondo.className = "sinFondo";
    }

  }

  ngOnInit() {
    if (!localStorage.getItem('token')) {
      this.router.navigateByUrl('/');
    }
  }

  setMenuActive(menu) {

    switch (menu) {
      case "registros":

        this.router.navigateByUrl('/registros');
        break;

      case "reporteSucursales":
        this.router.navigateByUrl('/reporteSucursales');
        break;

      case "reporteSecciones":
        this.router.navigateByUrl('/reporteSecciones');
        break;

      case "reporteBotones":
        this.router.navigateByUrl('/reporteBotones');
        break;

    }

  }

}
