import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GlobalVar } from '../../../../config';
import { log } from 'util';

@Injectable({
  providedIn: 'root'
})
export class SucursalesService {
  private url_list: string;
  private url_list_agregarSucursal: string;
  private url_list_eliminarSucursal: string;
  private url_list_editarSucursal: string;
  private url_list_getSucursalesUser: string;

  constructor(private http: HttpClient) {
    this.url_list = GlobalVar.URL_BASE + 'sucursales/getAllSucursales';
    this.url_list_agregarSucursal = GlobalVar.URL_BASE + 'sucursales/agregarSucursal';
    this.url_list_eliminarSucursal = GlobalVar.URL_BASE + 'sucursales/eliminarSucursal';
    this.url_list_editarSucursal = GlobalVar.URL_BASE + 'sucursales/editarSucursal';
    this.url_list_getSucursalesUser = GlobalVar.URL_BASE + 'sucursales/getSucursalesUser';

  }

  private static handleError(error: any): Promise<any> {
    console.error('Ha ocurrido un error: ', error);
    return Promise.reject(error.message || error);
  }

  getSucursales(cliente: number): Observable<any> {
    const arg = {
      cliente: cliente
    };

    return this.http
      .post(this.url_list, { arg: JSON.stringify(arg) })
      .pipe(map(data => data))
      .pipe(catchError(SucursalesService.handleError));
  }

  agregarSucursal(param): Observable<any> {
    return this.http
      .post(this.url_list_agregarSucursal, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(SucursalesService.handleError));
  }

  editarSucursal(param): Observable<any> {
    return this.http
      .post(this.url_list_editarSucursal, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(SucursalesService.handleError));
  }

  eliminarSucursal(param): Observable<any> {
    return this.http
      .post(this.url_list_eliminarSucursal, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(SucursalesService.handleError));
  }

  getSucursalesUser(cliente: number, usuario: number): Observable<any> {
    const arg = {
      cliente: cliente,
      usuario: usuario
    };

    return this.http
      .post(this.url_list_getSucursalesUser, { arg: JSON.stringify(arg) })
      .pipe(map(data => data))
      .pipe(catchError(SucursalesService.handleError));
  }

}
