import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { log } from 'util';

@Component({
  selector: 'app-menuReportes',
  templateUrl: './menuReportes.component.html',
  styleUrls: ['./menuReportes.component.scss']
})
export class MenuReportesComponent implements OnInit {

  menuRegistros: boolean;
  menuSucursales: boolean;
  menuSecciones: boolean;
  menuBotones: boolean;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  setMenuActive(menu) {

    switch (menu) {
      case "registros":
        this.router.navigateByUrl('/registros');
        break;

      case "reporteSucursales":
        this.router.navigateByUrl('/reporteSucursales');
        break;

      case "reporteSecciones":
        this.router.navigateByUrl('/reporteSecciones');
        break;

      case "reporteBotones":
        this.router.navigateByUrl('/reporteBotones');
        break;

    }

  }

}
