import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxLoadingModule } from 'ngx-loading';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { AppComponent } from './app.component';
import { CabeceraComponent } from './base/cabecera/cabecera.component';
import { MenuReportesComponent } from '../app/project/component/reportes/menuReportes/menuReportes.component';
import { PieComponent } from './base/pie/pie.component';
import { ErrorComponent } from './base/error/error.component';
import { AlertaComponent } from '../app/base/alerta/alerta.component';
import { CargandoComponent } from '../app/base/cargando/cargando.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxNotifierModule } from 'ngx-notifier';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

// Páginas

import { GestionarSucursalesComponent } from '../app/project/component/administracion/gestionarSucursales/gestionarSucursales.component';
import { GestionarSeccionesComponent } from '../app/project/component/administracion/gestionarSecciones/gestionarSecciones.component';
import { GestionarBotonesComponent } from '../app/project/component/administracion/gestionarBotones/gestionarBotones.component';
import { GestionarUsuariosComponent } from '../app/project/component/administracion/gestionarUsuarios/gestionarUsuarios.component';

import { InicioReportesComponent } from '../app/project/component/reportes/inicioReportes/inicio.component';
import { InicioAdmComponent } from '../app/project/component/administracion/inicioAdm/inicioAdm.component';
import { LoginComponent } from './login/login.component';
import { ReporteSucursalesComponent } from '../app/project/component/reportes/reporteSucursales/reporteSucursales.component';

import { RegistrosGeneralesComponent } from '../app/project/component/registrosGenerales/registrosGenerales.component';


import { ReporteResumenComponent } from '../app/project/component/reportes/reporteResumen/reporteResumen.component';
import { EstadisticasComponent } from '../app/project/component/estadisticas/estadisticas.component';
import { EstadisticasReporteComponent } from '../app/project/component/estadisticasReporte/estadisticasReporte.component';

import { ParametrosComponent } from '../app/project/component/parametros/parametros.component';
import { ReporteSeccionesComponent } from '../app/project/component/reportes/reporteSecciones/reporteSecciones.component';
import { ReporteBotonesComponent } from '../app/project/component/reportes/reporteBotones/reporteBotones.component';
import { ReporteGraficosComponent } from './project/component/reportes/reporteGraficos/reporteGraficos.component';


// Pipe
import { FormatoFechaPipe } from './project/pipe/formato-fecha.pipe';
import { FormatoFechaHoraPipe } from './project/pipe/formato-fecha-hora.pipe';

// Servicios

import { InvocaService } from './project/services/invoca.service';
import { UsuarioService } from './project/services/usuario.service';
import { RegistrosService } from './project/services/registros.service';
import { SeccionesService } from './project/services/secciones.service';
import { ReportesService } from './project/services/reportes.service';
import { UxService } from './project/services/ux.service';
import { ParametrosService } from './project/services/parametros.service';


//Primeng
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ColorPickerModule } from 'primeng/colorpicker';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ChartModule } from 'primeng/chart';
import { ProgressBarModule } from 'primeng/progressbar';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';


@NgModule({
  declarations: [
    AppComponent,
    CabeceraComponent,
    MenuReportesComponent,
    PieComponent,
    ErrorComponent,
    GestionarSucursalesComponent,
    GestionarBotonesComponent,
    GestionarUsuariosComponent,
    GestionarSeccionesComponent,
    InicioReportesComponent,
    InicioAdmComponent,
    EstadisticasComponent,
    EstadisticasReporteComponent,
    LoginComponent,
    ReporteSucursalesComponent,
    FormatoFechaPipe,
    AlertaComponent,
    CargandoComponent,
    RegistrosGeneralesComponent,
    ReporteResumenComponent,
    FormatoFechaHoraPipe,
    ReporteSeccionesComponent,
    ReporteGraficosComponent,
    ReporteBotonesComponent,
    ParametrosComponent

  ],
  entryComponents: [
    AlertaComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxDatatableModule,
    NgxLoadingModule.forRoot({}),
    ReactiveFormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    BrowserAnimationsModule,
    NgxChartsModule,
    FullCalendarModule,
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    NgxNotifierModule,
    ButtonModule,
    TableModule,
    DropdownModule,
    CalendarModule,
    InputSwitchModule,
    ColorPickerModule,
    KeyFilterModule,
    ChartModule,
    ProgressBarModule,
    MultiSelectModule,
    SelectButtonModule
  ],
  providers: [
    CargandoComponent,
    RegistrosService,
    ReportesService,
    SeccionesService,
    UsuarioService,
    UxService,
    ParametrosService,
    InvocaService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
