import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { log } from 'util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Panel de administración';

  constructor(
    private router: Router
  ) {
  }

  OnInit() {
    if (localStorage.getItem('usu_cliente')) {
      // Validar si token es válido o no
      // En caso de que no sea válido, enviar a login
      this.router.navigateByUrl('/inicioReportes');
    } else {
      localStorage.clear();
      this.router.navigateByUrl('/');
    }
  }
}
