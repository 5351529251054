import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuReportesComponent } from '../../component/reportes/menuReportes/menuReportes.component';
import { ReportesService } from '../../services/reportes.service';
import { log } from 'util';
import { UxService } from '../../services/ux.service';
import { SucursalesService } from '../../services/sucursales.service';

import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';


@Component({
  selector: 'app-estadisticas-reporte',
  templateUrl: './estadisticasReporte.component.html',
  styleUrls: ['./estadisticasReporte.component.scss'],
  providers: [MenuReportesComponent],
})
export class EstadisticasReporteComponent implements OnInit {
  usuarioNombre: string;
  usuario: number;



  cliente: number;
  seccion: any;
  rowsCasos: any = [];
  rows: any = [];
  sucursalSelect: number;
  secciones: any;
  sucursales: any;

  showError: boolean = false;
  tipoError: string = "";
  msgError: string = "";
  iconError: string = "";
  panelError: string = "";

  fechaInicio: any;
  fechaTermino: any;
  mostrarTabla: boolean = false;
  listCorreo: string[];

  modalRef: BsModalRef;

  locale = 'es';

  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  menuReportes: MenuReportesComponent;

  fechaActualizacion: Date;
  listData: any;
  listEstadisticas: any;

  datosGrafico: any[] = [];

  correoReporte = "";
  fechaString = "";
  multi2 = [];

  multi = [];

  view: any[] = [800, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabelDia = 'Días';
  xAxisLabelHora = 'Horas';
  showYAxisLabel = true;
  yAxisLabelDia = 'Cantidad';
  yAxisLabelHora = 'Cantidad';
  options: any;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  data: any;
  data2: any;
  idTime: any;
  idTime2: any;
  isNonFood = false;
  isCelular: boolean = false;
  mostrarFiltro: boolean = false;


  constructor(
    private router: Router,
    private reportesService: ReportesService,
    private sucursalesService: SucursalesService,
    private ux: UxService,
    private modalService: BsModalService,
    private route: ActivatedRoute
  ) {

    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      // this.mostrarMsg("success", "Éxito", "Entro un celular.");
      this.isCelular = true;
    }

    this.route.queryParamMap
      .subscribe((params) => {
        console.log(params);

        this.usuario = parseInt(params.get("usuario"));
        this.sucursalSelect = parseInt(params.get("sucursal"));
      });

    this.usuarioNombre = localStorage.getItem('usu_nombre');
    

    this.cliente = 1

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "sinFondo";
    }

    this.datosGrafico.push({ name: "llamar", value: 0 });
    this.datosGrafico.push({ name: "cancelar", value: 0 });
    this.datosGrafico.push({ name: "mensaje", value: 0 });

    var currentUtcTime = new Date();
    this.fechaActualizacion = (this.isCelular) ? currentUtcTime : new Date(currentUtcTime.toLocaleString('en-CL', { timeZone: 'America/Lima' }));

    Object.assign(this.datosGrafico)

    var mul = this.multi;

    Object.assign(this, { mul })

    this.listEstadisticas = [];



    this.getEstadisticasGenerales();

  }

  ngOnInit() {
  }

  getEstadisticasGenerales() {

    var currentUtcTime = new Date();
    this.fechaActualizacion = (this.isCelular) ? currentUtcTime : new Date(currentUtcTime.toLocaleString('en-CL', { timeZone: 'America/Lima' }));

    this.showCargando();

    let mes = ((this.fechaActualizacion.getMonth() + 1) <= 9) ? '0' + (this.fechaActualizacion.getMonth() + 1) : this.fechaActualizacion.getMonth() + 1;
    let dia = (this.fechaActualizacion.getDate() <= 9) ? '0' + (this.fechaActualizacion.getDate()) : this.fechaActualizacion.getDate();

    this.fechaString = dia + '/' + mes + '/' + this.fechaActualizacion.getFullYear();

    let param = {
      cliente: this.cliente,
      usuario: this.usuario,
      sucursal: this.sucursalSelect,
      seccion: "-1",
      fechaInicio: this.fechaActualizacion.getFullYear() + '-' + mes + '-' + dia + '' + 'T00:00:00',
      fechaTermino: this.fechaActualizacion.getFullYear() + '-' + mes + '-' + dia + '' + 'T23:59:59',
    }

    this.reportesService.getEstadisticasGenerales(param)
      .subscribe(dataRegistros => {

        if (dataRegistros == null) {
          this.ux.alerta("Error", "ha ocurrido un error.");
        } else if (dataRegistros.reporteSucursal.length > 0) {

          this.listData = dataRegistros.reporteSucursal;

          let totalNormales = 0;
          let totalFuera = 0;
          let totalPerdida = 0;

          this.listData.forEach(element => {

            if (element.promedio != null) {

              element.promedio = parseFloat(element.promedio).toFixed(2);

              var x = element.promedio;
              let int_part = Math.trunc(x);
              let float_part = x.split(".")[1]

              if (int_part > 0) {
                element.promedio = int_part + " min - " + float_part + " sg"
              } else {
                element.promedio = float_part + " sg"
              }

            } else {
              element.promedio = 0;
            }

            if (element.promedioRetiro != null) {

              element.promedioRetiro = parseFloat(element.promedioRetiro).toFixed(2);

              var x1 = element.promedioRetiro;
              let int_part = Math.trunc(x1);
              let float_part = x1.split(".")[1]

              if (int_part > 0) {
                element.promedioRetiro = int_part + " min - " + float_part + " sg"
              } else {
                element.promedioRetiro = float_part + " sg"
              }

            } else {
              element.promedioRetiro = 0;
            }

            element.rendimiento = (element.rendimiento != null) ? parseFloat(element.rendimiento).toFixed(2) : 0;
            element.rendimientoRetiro = (element.rendimientoRetiro != null) ? parseFloat(element.rendimientoRetiro).toFixed(2) : 0;

            let normalNonFood = 0;
            let fueraNonFood = 0;
            let perdidaNonFood = 0;

            element.secciones.forEach(element2 => {

              if (element2.sec_seccion == 'Non-Food') {
                this.isNonFood = true;
                normalNonFood = parseInt(element2.normal);
                fueraNonFood = parseInt(element2.fueraTiempo);
                perdidaNonFood = parseInt(element2.perdida);

              } else {
                this.isNonFood = false;
              }

              if (element2.promedio != null) {

                element2.promedio = parseFloat(element2.promedio).toFixed(2);

                var x = element2.promedio;
                let int_part = Math.trunc(x);
                let float_part = x.split(".")[1]

                if (int_part > 0) {
                  element2.promedio = int_part + " min - " + float_part + " sg"
                } else {
                  element2.promedio = float_part + " sg"
                }
              } else {
                element2.promedio = 0;
              }


              element2.rendimiento = (element2.rendimiento != null) ? parseFloat(element2.rendimiento).toFixed(2) : 0;
              element2.rendimientoRetiro = (element2.rendimientoRetiro != null) ? parseFloat(element2.rendimientoRetiro).toFixed(2) : 0;
            });

            totalNormales = parseInt(element.normal) + parseInt(element.normalRetiro) + (normalNonFood);
            totalFuera = parseInt(element.fueraTiempo) + parseInt(element.fueraTiempoRetiro) + (fueraNonFood);
            totalPerdida = parseInt(element.perdida) + parseInt(element.perdidaRetiro) + (perdidaNonFood);

          });

          this.listEstadisticas[0] = dataRegistros.estadisticasHora[0];
          this.listEstadisticas[1] = dataRegistros.estadisticasHora[1];
          this.listEstadisticas[2] = dataRegistros.estadisticasHora[2];
          this.listEstadisticas[3] = dataRegistros.estadisticasHora[3];

          // let totalNormales = 0;
          // for (let index = 0; index < this.listEstadisticas[1].length; index++) {
          //   totalNormales = totalNormales + this.listEstadisticas[1][index];
          // }

          // let totalFuera = 0;
          // for (let index = 0; index < this.listEstadisticas[2].length; index++) {
          //   totalFuera = totalFuera + this.listEstadisticas[2][index];
          // }

          // let totalPerdida = 0;
          // for (let index = 0; index < this.listEstadisticas[3].length; index++) {
          //   totalPerdida = totalPerdida + this.listEstadisticas[3][index];
          // }

          this.data = {
            labels: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
            datasets: [
              {
                label: 'Total',
                data: this.listEstadisticas[0],
                fill: false,
                borderColor: 'blue'
              },
              {
                label: 'Normal',
                data: this.listEstadisticas[1],
                fill: false,
                borderColor: 'green'
              },
              {
                label: 'Fuera de tiempo',
                data: this.listEstadisticas[2],
                fill: false,
                borderColor: 'yellow'
              },
              {
                label: 'Pérdida',
                data: this.listEstadisticas[3],
                fill: false,
                borderColor: 'red'
              }
            ]
          }

          this.data2 = {
            labels: ['Normal', 'Fuera de tiempo', 'Perdida'],
            datasets: [
              {
                data: [totalNormales, totalFuera, totalPerdida],
                backgroundColor: [
                  "green",
                  "yellow",
                  "red"
                ],
                hoverBackgroundColor: [
                  "green",
                  "yellow",
                  "red"
                ]
              }]
          };

        }

        this.hideCargando();

      }, err => {
        this.hideCargando();
        console.log('Error al invocar a reportesService.getReporteCasos: ', err);
      }, () => {

        this.idTime2 = setInterval(() => {

          this.createReporte();

        }, 1000);
      }
      );

  }


  // **************************** FUNCIONES DE VISTA ****************************


  onSucursalChange(event) {
    this.sucursalSelect = event;
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

  mostrarError(tipo, msg) {

    this.tipoError = tipo

    switch (this.tipoError) {
      case 'Error':
        this.iconError = "fa fa-close";
        this.panelError = "alert-danger";

        break;

      case 'Advertencia':

        this.iconError = "fa fa-exclamation-circle";
        this.panelError = "alert-warning";

        break;

    }

    this.msgError = msg
    this.showError = true;
  }

  setMenuActive(menu) {

    switch (menu) {
      case "registros":

        this.router.navigateByUrl('/registros');
        break;

      case "reporteSucursales":
        this.router.navigateByUrl('/reporteSucursales');
        break;

      case "reporteSecciones":
        this.router.navigateByUrl('/reporteSecciones');
        break;

      case "botones":
        this.router.navigateByUrl('/reporteBotones');
        break;

    }

  }

  enviarReporte() {

    let correo = "";

    if (this.listCorreo.length >= 2) {
      for (let index = 0; index < this.listCorreo.length; index++) {
        if (index == (this.listCorreo.length - 1)) {
          correo = correo + this.listCorreo[index];
        } else {
          correo = correo + this.listCorreo[index] + ", "
        }
      }
    } else {
      correo = correo + this.listCorreo[0];
    }

    let param = {
      cliente: this.cliente,
      correo: correo
    }

    this.showCargando();

    this.reportesService.enviarReporteHoy(param)
      .subscribe(data => {

        if (data.status === 'ERROR') {
          this.ux.alerta(data.error.message);
        } else if (data.status === 'OK') {
          this.ux.notificacion('Correo enviado correctamente.', 'success');
        }

        this.hideCargando();

        this.cerrarModalReporte();

      });

  }

  validarCorreo(correo) {
    var patron = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;

    if (correo.search(patron) == 0) {
      return true;
    } else {
      return false;
    }
  }

  showModalReporte(template) {
    this.listCorreo = []
    this.modalRef = this.modalService.show(template, { class: '', ignoreBackdropClick: true });

    this.createReporte();
  }

  cerrarModalReporte() {
    this.modalRef.hide();
  }

  addCorreo() {
    if (this.validarCorreo(this.correoReporte)) {
      this.listCorreo.push(this.correoReporte);
      this.correoReporte = "";
    } else {
      this.ux.notificacion('Ingrese un correo válido.', 'warning');
    }
  }

  createReporte() {

    this.showCargando();
    let data = document.getElementById('reporteHoy');

    const options = {
      background: 'white',
      scale: 1,
      allowTaint: true,
      scrollX: 0,
      scrollY: -window.scrollY
    };

    if (data != null) {

      html2canvas(data, options).then((canvas) => {

        var imgWidth = 210;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/jpeg', 1.0)
        let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)

        var res = pdf.output('datauristring');

        let param = {
          res: res,
          usuario: this.usuario
        }

        this.reportesService.crearReporte(param)
          .subscribe(data => {
          });
      });
    }

    clearInterval(this.idTime2);
    clearInterval(this.idTime);

    this.hideCargando();

    this.mostrarTabla = true;

  }

  descargarReporte() {

    this.showCargando();

    let data = document.getElementById('reporteHoy');

    const options = {
      background: 'white',
      scale: 1,
      allowTaint: true,
      scrollX: 0,
      scrollY: -window.scrollY
    };

    html2canvas(data, options).then((canvas) => {

      var imgWidth = 210;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('reporte_dia_' + this.fechaString + '.pdf'); // Generated PDF 

      this.hideCargando();

    });

  }


}
