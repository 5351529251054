import { Component, OnInit, TemplateRef, ViewChild, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UxService } from '../../../services/ux.service';
import { SucursalesService } from '../../../services/sucursales.service';
import { SeccionesService } from '../../../services/secciones.service';

import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { log } from 'util';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';


@Component({
  selector: 'app-gestionar-secciones',
  templateUrl: './gestionarSecciones.component.html',
  styleUrls: ['./gestionarSecciones.component.scss']
})
export class GestionarSeccionesComponent implements OnInit {

  cliente: number;
  seccionSelect: any;
  secciones: any[];
  sucursales: any = [];

  idSucursalAdd: number;
  nombreSeccionAdd: string = "";

  idSucursalEdit: number;
  nombreSeccionEdit: string = "";


  loadingIndicator: boolean;
  reorderable: boolean;
  modalRef: BsModalRef;

  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };


  @ViewChild('modalEditar') modalEditar;
  @ViewChild('modalEliminar') modalEliminar;

  constructor(
    private router: Router,
    private ux: UxService,
    private modalService: BsModalService,
    private sucursalesService: SucursalesService,
    private seccionesService: SeccionesService,

  ) {
    this.loadingIndicator = true;
    this.reorderable = true;
    this.cliente = parseInt(localStorage.getItem('usu_cliente'), 10);

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "sinFondo";
    }


    this.getSecciones();

  }

  ngOnInit() {
  }

  getSecciones() {

    this.showCargando();

    this.seccionesService.getSecciones(this.cliente)
      .subscribe(dataSecciones => {

        this.hideCargando();

        if (dataSecciones.status === 'ERROR') {

          console.log('Error al invocar a seccionesService.getSecciones: ', dataSecciones.error);

        } else if (dataSecciones.status === 'OK') {

          this.secciones = dataSecciones.data;

        }

      }, err => {
        this.hideCargando();
        console.log('Error al invocar a seccionesService.getSecciones: ', err);
      });

  }

  getSucursales() {
    this.showCargando();
    this.sucursalesService.getSucursales(this.cliente)
      .subscribe(dataSucursales => {
        this.hideCargando();
        if (dataSucursales.status === 'ERROR') {
          console.log('Error al invocar a sucursalesService.getSucursales: ', dataSucursales.error);
        } else if (dataSucursales.status === 'OK') {
          this.sucursales = dataSucursales.data;
        }
      }, err => {
        this.hideCargando();
        console.log('Error al invocar a sucursalesService.getSucursales: ', err);
      });
  }

  // ******************* FUNCION AGREGAR SUCURSAL *******************


  agregarSecciones() {

    if (this.idSucursalAdd == null || this.idSucursalAdd == 0 || this.nombreSeccionAdd == '') {
      this.ux.notificacion('Debe completar los campos de la sección.', 'danger');
    } else {

      this.showCargando();

      let param = {
        idCliente: this.cliente,
        idSucursal: this.idSucursalAdd,
        nombreSeccion: this.nombreSeccionAdd
      }

      this.seccionesService.agregarSecciones(param)
        .subscribe(data => {

          this.hideCargando();

          if (data.status === 'ERROR') {

            console.log('Error al invocar a seccionesService.agregarSecciones: ', data.error);

          } else if (data.status === 'OK') {
            this.getSecciones();
          }

        }, err => {
          this.hideCargando();
          console.log('Error al invocar a seccionesService.agregarSecciones: ', err);
        });

      this.modalRef.hide();

    }
  }

  showModalAgregarSecciones(template) {

    this.getSucursales();
    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  cerrarModalAgregarSecciones() {
    this.modalRef.hide();
  }


  // ******************* FUNCION EDITAR SUCURSAL *******************


  editarSecciones() {

    if (this.idSucursalEdit == null || this.idSucursalEdit == 0 || this.seccionSelect.sec_id == null || this.seccionSelect.sec_id == 0 ||
      this.nombreSeccionEdit == '') {
      this.ux.notificacion('Debe completar los campos de la sección.', 'danger');
    } else {

      this.showCargando();

      let param = {
        idCliente: this.cliente,
        idSucursal: this.idSucursalEdit,
        idSeccion: this.seccionSelect.sec_id,
        nombreSeccion: this.nombreSeccionEdit
      }

      this.seccionesService.editarSecciones(param)
        .subscribe(data => {

          this.hideCargando();

          if (data.status === 'ERROR') {

            console.log('Error al invocar a seccionesService.editarSecciones: ', data.error);

          } else if (data.status === 'OK') {
            this.getSecciones();
          }

        }, err => {
          this.hideCargando();
          console.log('Error al invocar a seccionesService.editarSecciones: ', err);
        });

      this.modalRef.hide();

    }
  }

  showModalEditarSecciones(template, seccion) {

    this.seccionSelect = seccion;

    this.getSucursales();

    this.idSucursalEdit = this.seccionSelect.sec_sucursal;
    this.nombreSeccionEdit = this.seccionSelect.sec_seccion;

    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  cerrarModalEditarSecciones() {
    this.modalRef.hide();
  }


  // ******************* FUNCION ELININAR SUCURSAL *******************


  eliminarSecciones() {

    let param = {
      idCliente: this.cliente,
      idSeccion: this.seccionSelect.sec_id
    }

    this.seccionesService.eliminarSecciones(param)
      .subscribe(data => {

        this.hideCargando();

        if (data.status === 'ERROR') {

          console.log('Error al invocar a seccionesService.eliminarSecciones: ', data.error);

        } else if (data.status === 'OK') {
          this.getSecciones();
        }

      }, err => {
        this.hideCargando();
        console.log('Error al invocar a seccionesService.eliminarSecciones: ', err);
      });

    this.modalRef.hide();
  }

  showModalEliminarSecciones(template, seccion) {
    this.seccionSelect = seccion;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  cerrarModalEliminarSecciones() {
    this.modalRef.hide();
  }


  //  *********************************** FUNCIONES DE VISTA ***********************************


  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
