import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GlobalVar } from '../../../../config';

@Injectable({
  providedIn: 'root'
})
export class BotonesService {
  private url_getBotonesSucursalSeccion;
  private url_getAllBotones;
  private url_agregarBotones;
  private url_editarBotones;
  private url_eliminarBotones;

  constructor(private http: HttpClient) {
    this.url_getBotonesSucursalSeccion = GlobalVar.URL_BASE + 'botones/getBotonesSucursalSeccion';
    this.url_getAllBotones = GlobalVar.URL_BASE + 'botones/getAllBotones';
    this.url_agregarBotones = GlobalVar.URL_BASE + 'botones/agregarBotones';
    this.url_editarBotones = GlobalVar.URL_BASE + 'botones/editarBotones';
    this.url_eliminarBotones = GlobalVar.URL_BASE + 'botones/eliminarBotones';
  }

  private static handleError(error: any): Promise<any> {
    console.error('Ha ocurrido un error: ', error);
    return Promise.reject(error.message || error);
  }

  getAllBotones(param): Observable<any> {
    return this.http
      .post(this.url_getAllBotones, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(BotonesService.handleError));
  }

  getBotonesSucursalSeccion(cliente: number, sucursal: number, seccion: number): Observable<any> {
    const arg = {
      cliente: cliente,
      sucursal: sucursal,
      seccion: seccion
    };

    return this.http
      .post(this.url_getBotonesSucursalSeccion, { arg: JSON.stringify(arg) })
      .pipe(map(data => data))
      .pipe(catchError(BotonesService.handleError));
  }

  agregarBotones(param): Observable<any> {
    return this.http
      .post(this.url_agregarBotones, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(BotonesService.handleError));
  }

  editarBotones(param): Observable<any> {
    return this.http
      .post(this.url_editarBotones, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(BotonesService.handleError));
  }

  eliminarBotones(param): Observable<any> {
    return this.http
      .post(this.url_eliminarBotones, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(BotonesService.handleError));
  }



}
