import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuarioService } from '../../app/project/services/usuario.service';
import { BotonesService } from '../../app/project/services/botones.service';
import { UxService } from '../../app/project/services/ux.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  accederForm: FormGroup;
  submitted = false;

  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,

    private ux: UxService,

  ) { }

  ngOnInit() {
    this.accederForm = this.formBuilder.group({
      correo: ['', [Validators.required]],
      contrasena: ['', [Validators.required]]
    });

    if (localStorage.getItem('token')) {
      this.router.navigateByUrl('/inicioReportes');
    }
  }

  get f() {
    return this.accederForm.controls;
  }

  acceder() {
    this.submitted = true;

    if (this.accederForm.invalid) {
      return;
    }

    // Iniciar sesión

    this.showCargando();

    let params = {
      correo: this.accederForm.value.correo,
      contrasena: this.accederForm.value.contrasena,
    }

    this.usuarioService.login(params)
      .subscribe(dataLogin => {

        if (dataLogin.validado == true) {

          // Obtener información usuario

          this.hideCargando();

          const persona = dataLogin.infoUser;

          localStorage.setItem('token', 'tokenSensores');
          localStorage.setItem('usu_id', persona.usu_id);
          localStorage.setItem('usu_nombre', persona.usu_nombre);
          localStorage.setItem('usu_correo', persona.usu_correo);
          localStorage.setItem('usu_cliente', persona.usu_cliente);
          localStorage.setItem('usu_tipo', persona.usu_tipo);

          if (persona.usu_tipo == 1) {
            this.router.navigateByUrl('/inicioReportes');
          } else if (persona.usu_tipo == 2) {
            this.router.navigateByUrl('/estadisticas');
          }

        } else if (dataLogin.validado == false) {
          this.hideCargando();
          this.ux.notificacion('Usuario o contraseña incorrectos.', 'danger');
        }

      }, err => {
        this.hideCargando();
        this.ux.alerta(err);
        console.log('Error al invocar a usuarioService.login: ', err);
      });
  }

  //  *********************************** FUNCIONES DE VISTA ***********************************


  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }
}
