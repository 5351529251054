import { Injectable } from '@angular/core';
import { GlobalVar } from '../../../../config';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ReporteMonitoreo } from '../entities/ReporteMonitoreo';
import { FormatoFechaHoraPipe } from '../pipe/formato-fecha-hora.pipe';

@Injectable({
  providedIn: 'root'
})
export class RegistrosService {
  private url_list: string;
  private url_list1: string;
  private url_list2: string;
  private url_list3: string;

  constructor(
    private http: HttpClient
  ) {
    this.url_list = GlobalVar.URL_BASE + 'registros/reporteMonitoreoLog';
    this.url_list1 = GlobalVar.URL_BASE + 'registros/atenderLlamado';
  }

  private static handleError(error: any): Promise<any> {
    console.error('Ha ocurrido un error: ', error);
    return Promise.reject(error.message || error);
  }

  reporteMonitoreoLog(arg: any): Observable<any> {
    return this.http
      .post(this.url_list, { arg: JSON.stringify(arg) })
      .pipe(map(data => data))
      .pipe(catchError(RegistrosService.handleError));
  }

  atenderLlamado(arg: any): Observable<any> {
    return this.http
      .post(this.url_list1, { arg: JSON.stringify(arg) })
      .pipe(map(data => data))
      .pipe(catchError(RegistrosService.handleError));
  }


  // *************** SECCION MAPEO ***************


  mapReporteMonitoreo(m: any): ReporteMonitoreo[] {
    return m.map(this.toReporteMonitoreo);
  }

  toReporteMonitoreo(r: any): ReporteMonitoreo {

    // let descripcionCaso = "";

    // if ((r.caso2 == '' || r.caso2 == null) && (r.reg_accion == 'llamar')) {
    //   descripcionCaso = ' Activo ';
    // } else if (r.caso2 == '' || r.caso2 == null) {
    //   descripcionCaso = ' - ';
    // } else {
    //   descripcionCaso = r.caso2;
    // }

    // let record = "";

    // if (r.permite_record == 1) {

    //   // if ((r.reg_llamado == 1 || r.reg_llamado == 2) && r.dif >= 90 && r.dif < 180) {
    //   if ((r.difActual >= 90 && r.difActual < 180) && (r.FnSgte == '' || r.FnSgte == null)) {
    //     record = "Primero";
    //   } else if (r.dif >= 90 && r.dif < 180) {
    //     record = "Primero";
    //   } else if ((r.reg_llamado == 0 && r.dif >= 180) || r.dif > 180) {
    //     record = "Segundo";
    //   } else if (r.dif == null && r.difActual > 180) {
    //     record = "Segundo";
    //   } else {
    //     record = " - ";
    //   }
    // } else {
    //   record = " No permite ";
    // }

    let fechaInicio = FormatoFechaHoraPipe.formatoFecha(r.fechaIniAccion);
    let fechaTermino = FormatoFechaHoraPipe.formatoFecha(r.fechaTerAccion);

    let m = <ReporteMonitoreo>({
      nombreBtn: r.reg_boton,

      fechaInicioAccion: (r.fechaIniAccion),
      fechaInicioAccionString: fechaInicio,

      fechaTerminoAccion: (r.fechaTerAccion == null && r.difActual > 180) ? FormatoFechaHoraPipe.formatoFechaTerminoNull(r.fechaIniAccion) : (r.fechaTerAccion == null) ? ' - ' : r.fechaTerAccion,
      fechaTerminoAccionString: (r.fechaTerAccion == null && r.difActual > 180) ? FormatoFechaHoraPipe.formatoFechaTerminoNull(r.fechaIniAccion) : (r.fechaTerAccion == null) ? ' - ' : fechaTermino,

      accion: r.reg_accion + " - " + ((r.FnSgte == '' || r.FnSgte == null) ? ' ' : r.FnSgte),
      accionSgte: (r.FnSgte == '' || r.FnSgte == null) ? ' - ' : r.FnSgte,

      diferencia: (r.fechaTerAccion == null && r.difActual > 180) ? 180 : (r.dif == 0 || r.dif == null) ? ' - ' : (r.dif > 180) ? 180 : r.dif,
      diferenciaActual: (r.difActual == 0 || r.difActual == null) ? ' - ' : r.difActual,

      caso: (r.fechaTerAccion == null && r.difActual > 180) ? 'Pérdida' : (r.descripcionCaso == null || r.descripcionCaso == '') ? ' - ' : r.descripcionCaso,
      recordatorio: (r.permite_record == 0) ? 'No permite' : 'Permite',
    });

    // if (m.caso == "Insistencia") {
    //   m = null;
    // }

    return m;
  }

  mapReporteMonitoreoLog(m: any): ReporteMonitoreo[] {
    return m.map(this.toReporteMonitoreoLog);
  }

  toReporteMonitoreoLog(r: any): ReporteMonitoreo {

    let m = <ReporteMonitoreo>({
      idRegistro: r.reg_id,
      idBoton: r.reg_boton,
      sucursal: r.nombreSucursal,
      seccion: r.nombreSeccion,
      nombreBtn: r.nombreBtn,
      fechaInicioAccion: (r.fecha),
      fechaInicioAccionString: r.fecha,
      accion: (r.accion == 'llamar') ? 'asistencia' : r.accion,
      fechaEjecucion: r.fechaEjec,
      ejecucion: (r.ejecucion == null || r.ejecucion == 'null' || r.ejecucion == '') ? 'Sin atender' : r.ejecucion,
    });
    return m;
  }

  mapReporteMonitoreoCasos(m: any): any[] {
    return m.map(this.toReporteMonitoreoCasos);
  }

  toReporteMonitoreoCasos(r: any): any {

    let m = <any>({
      sucursal: r.suc_nombre,
      seccion: r.sec_seccion,
      accion: (r.accion == 'llamar') ? 'asistencia' : r.accion,
      accionSgte: (r.accionSgte == 'llamar') ? 'asistencia' : (r.accionSgte == null && (r.caso == 'Perdida' || r.caso == 'Perdida retiro')) ? 'Cierre automático' : r.accionSgte,
      caso: (r.caso == 'Perdida') ? 'Pérdida' : (r.caso == 'Perdida retiro') ? 'Pérdida retiro' : r.caso,
      dif: (r.dif != null) ? r.dif : (r.difActual > 120 && r.sec_seccion == 'Lineal de cajas') ? "120 > " : (r.difActual > 180 && r.sec_seccion == 'Non-Food') ? "180 > " : r.difActual,
      difActual: r.difActual,
      boton: r.reg_boton,
      // nombreBtn: r.nombreBtn,
      fechaInicioAccionString: r.fechaInicio,
      fechaTerminoAccionString: (r.fechaTermino == null) ? '-' : r.fechaTermino,

    });
    return m;
  }


  mapReporteMonitoreoControlFilaLog(m: any): ReporteMonitoreo[] {
    return m.map(this.toReporteMonitoreoControlFilaLog);
  }

  toReporteMonitoreoControlFilaLog(r: any): ReporteMonitoreo {

    let fechaInicio = FormatoFechaHoraPipe.formatoFecha(r.fecha);

    let m = <ReporteMonitoreo>({
      sucursal: r.nombreSucursal,
      seccion: r.nombreSeccion,
      nombreBtn: r.nombreBtn,
      fechaInicioAccion: (r.fecha),
      fechaInicioAccionString: fechaInicio,
      accion: r.accion,
      turno: r.turno,
    });
    return m;
  }


  mapReporteTotalesControlFila(m: any): ReporteMonitoreo[] {
    return m.map(this.toReporteTotalesControlFila);
  }

  toReporteTotalesControlFila(r: any): ReporteMonitoreo {

    let fechaInicio = FormatoFechaHoraPipe.formatoFecha(r.fecha);

    let m = <ReporteMonitoreo>({
      nombreBtn: r.nombreBoton,
      fechaInicioAccion: (r.fecha),
      fechaInicioAccionString: fechaInicio,
      accion: r.accion,
      turno: r.turno,
    });
    return m;
  }

  deleteNulls(m: any): ReporteMonitoreo[] {

    let array = [];

    m.forEach(element => {

      if (element != null)
        array.push(element);
    });

    return array;

  }

  deleteNullCasos(m: any): any[] {

    let array = [];

    m.forEach(element => {
      if (element != null && ((element.accionSgte != 'asistencia') && (element.accion == 'asistencia' && element.accionSgte != 'retiro') || (element.accion == 'retiro' && element.accionSgte != 'asistencia')))
        array.push(element);
    });

    return array;

  }

}

